import { meta, Meta } from '@aksia-monorepo/shared-ui';
import { plainToClass, Type } from 'class-transformer';
import { TagCategoryEnum } from '../../enums/enums';
import { Fund } from '../fund/fund.model';

export class Taxonomy {
  //#region Main Hierarchy

  static mainHierarchy?: Array<Sector>;
  static primaryRegions?: Array<Tag>;

  //#endregion

  //#region Structure

  static internalPriorityLevel?: Array<Tag>;

  //#endregion

  //#region Other SubStrategies

  static hfSubstrategy?: Array<Tag>;
  static pcSubstrategy?: Array<Tag>;
  static peSubstrategy?: Array<Tag>;
  static raSubstrategy?: Array<Tag>;
  static reSubstrategy?: Array<Tag>;
  static loSubstrategy?: Array<Tag>;

  //#endregion

  //#region Geography

  static geography?: Array<Tag>;
  static regionFocus?: Array<Tag>;
  static countryFocus?: Array<Tag>;

  //#endregion

  //#region Industry

  static industry?: Array<Tag>;
  static industrySector?: Array<Tag>;
  static industryGroup?: Array<Tag>;

  //#endregion

  //#region ESG

  static esg?: Array<Tag>;
  static wmdOwnership?: Array<Tag>;
  static wmdOwnershipRate?: number;
  static esgRegulated?: Array<Tag>;
  static diversityCertifications?: Array<Tag>;

  //#endregion

  //#region Hedge Fund

  static marketCapHF?: Array<Tag>;
  static portfolioUtility?: Array<Tag>;
  static primaryRiskDriver?: Array<Tag>;
  static secondaryRiskDriver?: Array<Tag>;
  static stressEquityBeta?: Array<Tag>;
  static hfPortfolioFocus?: Array<Tag>;

  //#endregion

  //#region Private Credit

  static origination?: Array<Tag>;
  static seniority?: Array<Tag>;
  static incomeSource?: Array<Tag>;
  static underlyingAssetClass?: Array<Tag>;
  static marketCapPC?: Array<Tag>;
  static borrowerStatus?: Array<Tag>;
  static leverage?: Array<Tag>;
  static pcPortfolioFocus?: Array<Tag>;

  //#endregion

  //#region Private Equity

  static marketCapPE?: Array<Tag>;
  static dealStrategy?: Array<Tag>;
  static pePortfolioFocus?: Array<Tag>;
  static ownership?: Array<Tag>;
  static buyoutFundStyle?: Array<Tag>;
  static coInvestmentActivity?: Array<Tag>;
  static priorityLevel?: Array<Tag>;
  static gpLedTransactionTypes?: Array<Tag>;
  static secondaryInvestmentStage?: Array<Tag>;
  static ventureCapitalAndGrowthEquityIndustryExposure?: Array<Tag>;
  static growthEquityTargetedRevenueGrowth?: Array<Tag>;
  static growthEquityTargetedRevenue?: Array<Tag>;
  static growthEquityTargetedProfitability?: Array<Tag>;
  static gpStakesTargetedAssetsUnderManagement?: Array<Tag>;
  static northAmericaExposure?: Array<Tag>;
  static europeExposure?: Array<Tag>;
  static asiaAndPacificExposure?: Array<Tag>;
  static southOrLatinAmericaExposure?: Array<Tag>;
  static africaExposure?: Array<Tag>;
  static buyoutExposureAerospaceAndDefense?: Array<Tag>;
  static buyoutExposureBusinessServices?: Array<Tag>;
  static buyoutExposureConsumer?: Array<Tag>;
  static buyoutExposureCommunicationsAndMedia?: Array<Tag>;
  static buyoutExposureEducation?: Array<Tag>;
  static buyoutExposureEnergy?: Array<Tag>;
  static buyoutExposureFinancials?: Array<Tag>;
  static buyoutExposureGovernmentServices?: Array<Tag>;
  static buyoutExposureHealthCare?: Array<Tag>;
  static buyoutExposureIndustrials?: Array<Tag>;
  static buyoutExposureMaterials?: Array<Tag>;
  static buyoutExposureSports?: Array<Tag>;
  static buyoutExposureTechnologyHardware?: Array<Tag>;
  static buyoutExposureTechnologySoftware?: Array<Tag>;
  static buyoutExposureTransportationAndLogistics?: Array<Tag>;
  static buyoutExposureRealEstate?: Array<Tag>;
  static buyoutExposureUtilities?: Array<Tag>;
  static gpLedTransactionType?: Array<Tag>;
  static lpLedTransactionType?: Array<Tag>;
  static strategicPrimariesTransactionType?: Array<Tag>;
  static secondaryTransactionType0To100Mn?: Array<Tag>;
  static secondaryTransactionType100To250Mn?: Array<Tag>;
  static secondaryTransactionType250To500Mn?: Array<Tag>;
  static secondaryTransactionType500To1000Mn?: Array<Tag>;
  static secondaryTransactionType1000PlusMn?: Array<Tag>;

  //#endregion

  //#region Real Asset

  static infraRiskProfile?: Array<Tag>;
  static raPortfolioFocus?: Array<Tag>;

  //#endregion

  //#region Real Estate

  static primaryPropertyType?: Array<Tag>;
  static managerProfile?: Array<Tag>;
  static usRegionalFocus?: Array<Tag>;
  static investmentStrategyDetails?: Array<Tag>;
  static rePortfolioFocus?: Array<Tag>;

  //#endregion

  //#region Multi Asset Class
  static marketCapLO?: Array<Tag>;
  static styleLo?: Array<Tag>;
  static approachLo?: Array<Tag>;
  static renewableGeneration?: Array<Tag>;

  //#endregion
}

export class TaxonomyMeta extends Meta {
  //#region Main Hierarchy

  @Type(() => EntitySector)
  entitySectors?: Array<EntitySector>;

  @Type(() => EntityStrategy)
  entityStrategies?: Array<EntityStrategy>;

  @Type(() => SubStrategy)
  entitySubStrategies?: Array<SubStrategy>;

  @Type(() => EntityRegion)
  entityRegions?: Array<EntityRegion>;

  //#endregion

  //#region Structure

  @meta({
    alias: 'Internal Priority Level',
    isInaudible: true,
    auditResponseProp: 'InternalPriorityLevel',
    updates: (self: Meta, value: number) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategorySingle(
          self.parent,
          TagCategoryEnum.InternalPriorityLevel,
          value
        );
      }
    },
  })
  internalPriorityLevel?: number;

  //#endregion

  //#region Other Substrategies

  @Type(() => Tag)
  @meta({
    alias: 'Hedge Fund',
    isInaudible: true,
    auditResponseProp: 'HFSubstrategy',
    updates: (self: Meta, value: Array<Tag>) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategoryMulti(
          self.parent,
          TagCategoryEnum.HFSubstrategy,
          value
        );
      }
    },
  })
  hfSubstrategyTags?: Array<Tag>;

  @Type(() => Tag)
  @meta({
    alias: 'Private Credit',
    isInaudible: true,
    auditResponseProp: 'PCSubstrategy',
    updates: (self: Meta, value: Array<Tag>) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategoryMulti(
          self.parent,
          TagCategoryEnum.PCSubstrategy,
          value
        );
      }
    },
  })
  pcSubstrategyTags?: Array<Tag>;

  @Type(() => Tag)
  @meta({
    alias: 'Private Equity',
    isInaudible: true,
    auditResponseProp: 'PESubstrategy',
    updates: (self: Meta, value: Array<Tag>) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategoryMulti(
          self.parent,
          TagCategoryEnum.PESubstrategy,
          value
        );
      }
    },
  })
  peSubstrategyTags?: Array<Tag>;

  @Type(() => Tag)
  @meta({
    alias: 'Real Asset',
    isInaudible: true,
    auditResponseProp: 'RASubstrategy',
    updates: (self: Meta, value: Array<Tag>) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategoryMulti(
          self.parent,
          TagCategoryEnum.RASubstrategy,
          value
        );
      }
    },
  })
  raSubstrategyTags?: Array<Tag>;

  @Type(() => Tag)
  @meta({
    alias: 'Real Estate',
    isInaudible: true,
    auditResponseProp: 'RESubstrategy',
    updates: (self: Meta, value: Array<Tag>) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategoryMulti(
          self.parent,
          TagCategoryEnum.RESubstrategy,
          value
        );
      }
    },
  })
  reSubstrategyTags?: Array<Tag>;

  @Type(() => Tag)
  @meta({
    alias: 'Long Only',
    isInaudible: true,
    auditResponseProp: 'LongOnlySubstrategy',
    updates: (self: Meta, value: Array<Tag>) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategoryMulti(
          self.parent,
          TagCategoryEnum.LongOnlySubstrategy,
          value
        );
      }
    },
  })
  loSubstrategyTags?: Array<Tag>;

  //#endregion

  //#region Geography

  @meta({
    alias: 'Geography',
    isInaudible: true,
    auditResponseProp: 'Geography',
    updates: (self: Meta, value: number) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategorySingle(
          self.parent,
          TagCategoryEnum.Geography,
          value
        );
      }
    },
  })
  geographyTag?: number;

  @Type(() => Tag)
  @meta({
    alias: 'Region Focus',
    isInaudible: true,
    auditResponseProp: 'RegionFocus',
    updates: (self: Meta, value: Array<Tag>) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategoryMulti(
          self.parent,
          TagCategoryEnum.RegionFocus,
          value
        );
      }
    },
  })
  regionFocusTags?: Array<Tag>;

  @Type(() => Tag)
  @meta({
    alias: 'Country Focus',
    isInaudible: true,
    auditResponseProp: 'CountryFocus',
    updates: (self: Meta, value: Array<Tag>) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategoryMulti(
          self.parent,
          TagCategoryEnum.CountryFocus,
          value
        );
      }
    },
  })
  countryFocusTags?: Array<Tag>;

  //#endregion

  //#region Industry

  @meta({
    alias: 'Industry',
    isInaudible: true,
    auditResponseProp: 'Industry',
    updates: (self: Meta, value: number) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategorySingle(
          self.parent,
          TagCategoryEnum.Industry,
          value
        );
      }
    },
  })
  industryTag?: number;

  @Type(() => Tag)
  @meta({
    alias: 'Industry Sector',
    isInaudible: true,
    auditResponseProp: 'IndustrySector',
    updates: (self: Meta, value: Array<Tag>) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategoryMulti(
          self.parent,
          TagCategoryEnum.IndustrySector,
          value
        );
      }
    },
  })
  industrySectorTags?: Array<Tag>;

  @Type(() => Tag)
  @meta({
    alias: 'Industry Group',
    isInaudible: true,
    auditResponseProp: 'IndustryGroup',
    updates: (self: Meta, value: Array<Tag>) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategoryMulti(
          self.parent,
          TagCategoryEnum.IndustryGroup,
          value
        );
      }
    },
  })
  industryGroupTags?: Array<Tag>;

  //#endregion

  //#region ESG

  @Type(() => Tag)
  @meta({
    alias: 'Responsible Investing',
    isInaudible: true,
    auditResponseProp: 'ESG',
    updates: (self: Meta, value: Array<Tag>) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategoryMulti(
          self.parent,
          TagCategoryEnum.ESG,
          value
        );
      }
    },
  })
  esgTags?: Array<Tag>;

  @meta({
    alias: 'Woman/Minority Ownership',
    isInaudible: true,
    auditResponseProp: 'WomanMinoriyDisabledOwnership',
    updates: (self: Meta, value: number) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategorySingle(
          self.parent,
          TagCategoryEnum.WomanMinoriyDisabledOwnership,
          value
        );
      }
    },
  })
  wmdOwnershipTag?: number;

  @Type(() => Tag)
  @meta({
    alias: 'Responsible Investing Regulated',
    isInaudible: true,
    auditResponseProp: 'ESGRegulated',
    updates: (self: Meta, value: Array<Tag>) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategoryMulti(
          self.parent,
          TagCategoryEnum.ESGRegulated,
          value
        );
      }
    },
  })
  esgRegulatedTags?: Array<Tag>;

  @meta({
    alias: 'Diversity Certifications',
    isInaudible: true,
    auditResponseProp: 'DiversityCertifications',
    updates: (self: Meta, value: Array<Tag>) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategoryMulti(
          self.parent,
          TagCategoryEnum.DiversityCertifications,
          value
        );
      }
    },
  })
  diversityCertificationsTag?: Array<Tag>;

  //#endregion

  //#region Hedge Fund

  @meta({
    alias: 'Market Cap HF',
    isInaudible: true,
    auditResponseProp: 'MarketCapHF',
    updates: (self: Meta, value: number) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategorySingle(
          self.parent,
          TagCategoryEnum.MarketCapHF,
          value
        );
      }
    },
  })
  marketCapHFTag?: number;

  @meta({
    alias: 'Portfolio Utility',
    isInaudible: true,
    auditResponseProp: 'PortfolioUtility',
    updates: (self: Meta, value: number) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategorySingle(
          self.parent,
          TagCategoryEnum.PortfolioUtility,
          value
        );
      }
    },
  })
  portfolioUtilityTag?: number;

  @meta({
    alias: 'Primary Risk Driver',
    isInaudible: true,
    auditResponseProp: 'PrimaryRiskDriver',
    updates: (self: Meta, value: number) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategorySingle(
          self.parent,
          TagCategoryEnum.PrimaryRiskDriver,
          value
        );
      }
    },
  })
  primaryRiskDriverTag?: number;

  @meta({
    alias: 'Secondary Risk Driver',
    isInaudible: true,
    auditResponseProp: 'SecondaryRiskDriver',
    updates: (self: Meta, value: number) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategorySingle(
          self.parent,
          TagCategoryEnum.SecondaryRiskDriver,
          value
        );
      }
    },
  })
  secondaryRiskDriverTag?: number;

  @meta({
    alias: 'Stress Equity Beta',
    isInaudible: true,
    auditResponseProp: 'StressEquityBeta',
    updates: (self: Meta, value: number) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategorySingle(
          self.parent,
          TagCategoryEnum.StressEquityBeta,
          value
        );
      }
    },
  })
  stressEquityBetaTag?: number;

  @Type(() => Tag)
  @meta({
    alias: 'HF Portfolio Focus',
    isInaudible: true,
    auditResponseProp: 'HFPortfolioFocus',
    updates: (self: Meta, value: Array<Tag>) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategoryMulti(
          self.parent,
          TagCategoryEnum.HFPortfolioFocus,
          value
        );
      }
    },
  })
  hfPortfolioFocusTags?: Array<Tag>;

  applicableHFCategories?: Array<number>;

  //#endregion

  //#region Private Credit

  @Type(() => Tag)
  @meta({
    alias: 'Origination',
    isInaudible: true,
    auditResponseProp: 'Origination',
    updates: (self: Meta, value: Array<Tag>) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategoryMulti(
          self.parent,
          TagCategoryEnum.Origination,
          value
        );
      }
    },
  })
  originationTags?: Array<Tag>;

  @Type(() => Tag)
  @meta({
    alias: 'Seniority',
    isInaudible: true,
    auditResponseProp: 'Seniority',
    updates: (self: Meta, value: Array<Tag>) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategoryMulti(
          self.parent,
          TagCategoryEnum.Seniority,
          value
        );
      }
    },
  })
  seniorityTags?: Array<Tag>;

  @Type(() => Tag)
  @meta({
    alias: 'Income Source',
    isInaudible: true,
    auditResponseProp: 'IncomeSource',
    updates: (self: Meta, value: Array<Tag>) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategoryMulti(
          self.parent,
          TagCategoryEnum.IncomeSource,
          value
        );
      }
    },
  })
  incomeSourceTags?: Array<Tag>;

  @Type(() => Tag)
  @meta({
    alias: 'Underlying Asset Class',
    isInaudible: true,
    auditResponseProp: 'UnderlyingAssetClass',
    updates: (self: Meta, value: Array<Tag>) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategoryMulti(
          self.parent,
          TagCategoryEnum.UnderlyingAssetClass,
          value
        );
      }
    },
  })
  underlyingAssetClassTags?: Array<Tag>;

  @Type(() => Tag)
  @meta({
    alias: 'Market Cap PC',
    isInaudible: true,
    auditResponseProp: 'MarketCapPC',
    updates: (self: Meta, value: Array<Tag>) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategoryMulti(
          self.parent,
          TagCategoryEnum.MarketCapPC,
          value
        );
      }
    },
  })
  marketCapPCTags?: Array<Tag>;

  @Type(() => Tag)
  @meta({
    alias: 'Borrower Status',
    isInaudible: true,
    auditResponseProp: 'BorrowerStatus',
    updates: (self: Meta, value: Array<Tag>) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategoryMulti(
          self.parent,
          TagCategoryEnum.BorrowerStatus,
          value
        );
      }
    },
  })
  borrowerStatusTags?: Array<Tag>;

  @meta({
    alias: 'Leverage',
    isInaudible: true,
    auditResponseProp: 'Leverage',
    updates: (self: Meta, value: number) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategorySingle(
          self.parent,
          TagCategoryEnum.Leverage,
          value
        );
      }
    },
  })
  leverageTag?: number;

  @Type(() => Tag)
  @meta({
    alias: 'PC Portfolio Focus',
    isInaudible: true,
    auditResponseProp: 'PCPortfolioFocus',
    updates: (self: Meta, value: Array<Tag>) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategoryMulti(
          self.parent,
          TagCategoryEnum.PCPortfolioFocus,
          value
        );
      }
    },
  })
  pcPortfolioFocusTags?: Array<Tag>;

  applicablePCCategories?: Array<number>;

  //#endregion

  //#region Private Equity

  @Type(() => Tag)
  @meta({
    alias: 'Transaction Sizes',
    isInaudible: true,
    auditResponseProp: 'MarketCapPE',
    updates: (self: Meta, value: Array<Tag>) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategoryMulti(
          self.parent,
          TagCategoryEnum.MarketCapPE,
          value
        );
      }
    },
  })
  marketCapPETags?: Array<Tag>;

  @Type(() => Tag)
  @meta({
    alias: 'Transaction Types',
    isInaudible: true,
    auditResponseProp: 'DealStrategy',
    updates: (self: Meta, value: Array<Tag>) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategoryMulti(
          self.parent,
          TagCategoryEnum.DealStrategy,
          value
        );
      }
    },
  })
  dealStrategyTags?: Array<Tag>;

  @Type(() => Tag)
  @meta({
    alias: 'Ownership',
    isInaudible: true,
    auditResponseProp: 'Ownership',
    updates: (self: Meta, value: Array<Tag>) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategoryMulti(
          self.parent,
          TagCategoryEnum.Ownership,
          value
        );
      }
    },
  })
  ownershipTags?: Array<Tag>;

  @Type(() => Tag)
  @meta({
    alias: 'PE Portfolio Focus',
    isInaudible: true,
    auditResponseProp: 'PEPortfolioFocus',
    updates: (self: Meta, value: Array<Tag>) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategoryMulti(
          self.parent,
          TagCategoryEnum.PEPortfolioFocus,
          value
        );
      }
    },
  })
  pePortfolioFocusTags?: Array<Tag>;

  @meta({
    alias: 'Buyout Fund Style',
    isInaudible: true,
    auditResponseProp: 'BuyoutFundStyle',
    updates: (self: Meta, value: number) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategorySingle(
          self.parent,
          TagCategoryEnum.BuyoutFundStyle,
          value
        );
      }
    },
  })
  buyoutFundStyleTag?: number;

  @meta({
    alias: 'Co-investment Activity',
    isInaudible: true,
    auditResponseProp: 'CoinvestmentActivity',
    updates: (self: Meta, value: number) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategorySingle(
          self.parent,
          TagCategoryEnum.CoinvestmentActivity,
          value
        );
      }
    },
  })
  coInvestmentActivityTag?: number;

  @meta({
    alias: 'Priority Level',
    isInaudible: true,
    auditResponseProp: 'PriorityLevel',
    updates: (self: Meta, value: number) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategorySingle(
          self.parent,
          TagCategoryEnum.PriorityLevel,
          value
        );
      }
    },
  })
  priorityLevelTag?: number;

  @Type(() => Tag)
  @meta({
    alias: 'GP-Led Transaction Types',
    isInaudible: true,
    auditResponseProp: 'GPLedTransactionTypes',
    updates: (self: Meta, value: Array<Tag>) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategoryMulti(
          self.parent,
          TagCategoryEnum.GPLedTransactionTypes,
          value
        );
      }
    },
  })
  gpLedTransactionTypesTags?: Array<Tag>;

  @Type(() => Tag)
  @meta({
    alias: 'Secondary Investment Stage',
    isInaudible: true,
    auditResponseProp: 'SecondaryInvestmentStage',
    updates: (self: Meta, value: Array<Tag>) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategoryMulti(
          self.parent,
          TagCategoryEnum.SecondaryInvestmentStage,
          value
        );
      }
    },
  })
  secondaryInvestmentStageTags?: Array<Tag>;

  @Type(() => Tag)
  @meta({
    alias: 'Venture Capital & Growth Equity Industry Exposure',
    isInaudible: true,
    auditResponseProp: 'VentureCapitalAndGrowthEquityIndustryExposure',
    updates: (self: Meta, value: Array<Tag>) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategoryMulti(
          self.parent,
          TagCategoryEnum.VentureCapitalAndGrowthEquityIndustryExposure,
          value
        );
      }
    },
  })
  ventureCapitalAndGrowthEquityIndustryExposureTags?: Array<Tag>;

  @Type(() => Tag)
  @meta({
    alias: 'Growth Equity Targeted Revenue Growth',
    isInaudible: true,
    auditResponseProp: 'GrowthEquityTargetedRevenueGrowth',
    updates: (self: Meta, value: Array<Tag>) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategoryMulti(
          self.parent,
          TagCategoryEnum.GrowthEquityTargetedRevenueGrowth,
          value
        );
      }
    },
  })
  growthEquityTargetedRevenueGrowthTags?: Array<Tag>;

  @Type(() => Tag)
  @meta({
    alias: 'Growth Equity Targeted Revenue',
    isInaudible: true,
    auditResponseProp: 'GrowthEquityTargetedRevenue',
    updates: (self: Meta, value: Array<Tag>) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategoryMulti(
          self.parent,
          TagCategoryEnum.GrowthEquityTargetedRevenue,
          value
        );
      }
    },
  })
  growthEquityTargetedRevenueTags?: Array<Tag>;

  @Type(() => Tag)
  @meta({
    alias: 'Growth Equity Targeted Profitability',
    isInaudible: true,
    auditResponseProp: 'GrowthEquityTargetedProfitability',
    updates: (self: Meta, value: Array<Tag>) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategoryMulti(
          self.parent,
          TagCategoryEnum.GrowthEquityTargetedProfitability,
          value
        );
      }
    },
  })
  growthEquityTargetedProfitabilityTags?: Array<Tag>;

  @Type(() => Tag)
  @meta({
    alias: 'GP Stakes Targeted Assets Under Management',
    isInaudible: true,
    auditResponseProp: 'GPStakesTargetedAssetsUnderManagement',
    updates: (self: Meta, value: Array<Tag>) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategoryMulti(
          self.parent,
          TagCategoryEnum.GPStakesTargetedAssetsUnderManagement,
          value
        );
      }
    },
  })
  gpStakesTargetedAssetsUnderManagementTags?: Array<Tag>;

  @meta({
    alias: 'North America Exposure',
    isInaudible: true,
    auditResponseProp: 'NorthAmericaExposure',
    updates: (self: Meta, value: number) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategorySingle(
          self.parent,
          TagCategoryEnum.NorthAmericaExposure,
          value
        );
      }
    },
  })
  northAmericaExposureTag?: number;

  @meta({
    alias: 'Europe Exposure',
    isInaudible: true,
    auditResponseProp: 'EuropeExposure',
    updates: (self: Meta, value: number) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategorySingle(
          self.parent,
          TagCategoryEnum.EuropeExposure,
          value
        );
      }
    },
  })
  europeExposureTag?: number;

  @meta({
    alias: 'Asia & Pacific Exposure',
    isInaudible: true,
    auditResponseProp: 'AsiaAndPacificExposure',
    updates: (self: Meta, value: number) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategorySingle(
          self.parent,
          TagCategoryEnum.AsiaAndPacificExposure,
          value
        );
      }
    },
  })
  asiaAndPacificExposureTag?: number;

  @meta({
    alias: 'South/Latin America Exposure',
    isInaudible: true,
    auditResponseProp: 'SouthOrLatinAmericaExposure',
    updates: (self: Meta, value: number) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategorySingle(
          self.parent,
          TagCategoryEnum.SouthOrLatinAmericaExposure,
          value
        );
      }
    },
  })
  southOrLatinAmericaExposureTag?: number;

  @meta({
    alias: 'Africa Exposure',
    isInaudible: true,
    auditResponseProp: 'AfricaExposure',
    updates: (self: Meta, value: number) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategorySingle(
          self.parent,
          TagCategoryEnum.AfricaExposure,
          value
        );
      }
    },
  })
  africaExposureTag?: number;

  @meta({
    alias: 'Buyout Exposure: Aerospace & Defense',
    isInaudible: true,
    auditResponseProp: 'BuyoutExposureAerospaceAndDefense',
    updates: (self: Meta, value: number) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategorySingle(
          self.parent,
          TagCategoryEnum.BuyoutExposureAerospaceAndDefense,
          value
        );
      }
    },
  })
  buyoutExposureAerospaceAndDefenseTag?: number;

  @meta({
    alias: 'Buyout Exposure: Business Services',
    isInaudible: true,
    auditResponseProp: 'BuyoutExposureBusinessServices',
    updates: (self: Meta, value: number) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategorySingle(
          self.parent,
          TagCategoryEnum.BuyoutExposureBusinessServices,
          value
        );
      }
    },
  })
  buyoutExposureBusinessServicesTag?: number;

  @meta({
    alias: 'Buyout Exposure: Consumer',
    isInaudible: true,
    auditResponseProp: 'BuyoutExposureConsumer',
    updates: (self: Meta, value: number) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategorySingle(
          self.parent,
          TagCategoryEnum.BuyoutExposureConsumer,
          value
        );
      }
    },
  })
  buyoutExposureConsumerTag?: number;

  @meta({
    alias: 'Buyout Exposure: Communications & Media',
    isInaudible: true,
    auditResponseProp: 'BuyoutExposureCommunicationsAndMedia',
    updates: (self: Meta, value: number) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategorySingle(
          self.parent,
          TagCategoryEnum.BuyoutExposureCommunicationsAndMedia,
          value
        );
      }
    },
  })
  buyoutExposureCommunicationsAndMediaTag?: number;

  @meta({
    alias: 'Buyout Exposure: Education',
    isInaudible: true,
    auditResponseProp: 'BuyoutExposureEducation',
    updates: (self: Meta, value: number) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategorySingle(
          self.parent,
          TagCategoryEnum.BuyoutExposureEducation,
          value
        );
      }
    },
  })
  buyoutExposureEducationTag?: number;

  @meta({
    alias: 'Buyout Exposure: Energy',
    isInaudible: true,
    auditResponseProp: 'BuyoutExposureEnergy',
    updates: (self: Meta, value: number) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategorySingle(
          self.parent,
          TagCategoryEnum.BuyoutExposureEnergy,
          value
        );
      }
    },
  })
  buyoutExposureEnergyTag?: number;

  @meta({
    alias: 'Buyout Exposure: Financials',
    isInaudible: true,
    auditResponseProp: 'BuyoutExposureFinancials',
    updates: (self: Meta, value: number) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategorySingle(
          self.parent,
          TagCategoryEnum.BuyoutExposureFinancials,
          value
        );
      }
    },
  })
  buyoutExposureFinancialsTag?: number;

  @meta({
    alias: 'Buyout Exposure: Government Services',
    isInaudible: true,
    auditResponseProp: 'BuyoutExposureGovernmentServices',
    updates: (self: Meta, value: number) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategorySingle(
          self.parent,
          TagCategoryEnum.BuyoutExposureGovernmentServices,
          value
        );
      }
    },
  })
  buyoutExposureGovernmentServicesTag?: number;

  @meta({
    alias: 'Buyout Exposure: Health Care',
    isInaudible: true,
    auditResponseProp: 'BuyoutExposureHealthCare',
    updates: (self: Meta, value: number) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategorySingle(
          self.parent,
          TagCategoryEnum.BuyoutExposureHealthCare,
          value
        );
      }
    },
  })
  buyoutExposureHealthCareTag?: number;

  @meta({
    alias: 'Buyout Exposure: Industrials',
    isInaudible: true,
    auditResponseProp: 'BuyoutExposureIndustrials',
    updates: (self: Meta, value: number) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategorySingle(
          self.parent,
          TagCategoryEnum.BuyoutExposureIndustrials,
          value
        );
      }
    },
  })
  buyoutExposureIndustrialsTag?: number;

  @meta({
    alias: 'Buyout Exposure: Materials',
    isInaudible: true,
    auditResponseProp: 'BuyoutExposureMaterials',
    updates: (self: Meta, value: number) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategorySingle(
          self.parent,
          TagCategoryEnum.BuyoutExposureMaterials,
          value
        );
      }
    },
  })
  buyoutExposureMaterialsTag?: number;

  @meta({
    alias: 'Buyout Exposure: Sports',
    isInaudible: true,
    auditResponseProp: 'BuyoutExposureSports',
    updates: (self: Meta, value: number) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategorySingle(
          self.parent,
          TagCategoryEnum.BuyoutExposureSports,
          value
        );
      }
    },
  })
  buyoutExposureSportsTag?: number;

  @meta({
    alias: 'Buyout Exposure: Technology - Hardware',
    isInaudible: true,
    auditResponseProp: 'BuyoutExposureTechnologyHardware',
    updates: (self: Meta, value: number) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategorySingle(
          self.parent,
          TagCategoryEnum.BuyoutExposureTechnologyHardware,
          value
        );
      }
    },
  })
  buyoutExposureTechnologyHardwareTag?: number;

  @meta({
    alias: 'Buyout Exposure: Technology - Software',
    isInaudible: true,
    auditResponseProp: 'BuyoutExposureTechnologySoftware',
    updates: (self: Meta, value: number) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategorySingle(
          self.parent,
          TagCategoryEnum.BuyoutExposureTechnologySoftware,
          value
        );
      }
    },
  })
  buyoutExposureTechnologySoftwareTag?: number;

  @meta({
    alias: 'Buyout Exposure: Transportation & Logistics',
    isInaudible: true,
    auditResponseProp: 'BuyoutExposureTransportationAndLogistics',
    updates: (self: Meta, value: number) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategorySingle(
          self.parent,
          TagCategoryEnum.BuyoutExposureTransportationAndLogistics,
          value
        );
      }
    },
  })
  buyoutExposureTransportationAndLogisticsTag?: number;

  @meta({
    alias: 'Buyout Exposure: Real Estate',
    isInaudible: true,
    auditResponseProp: 'BuyoutExposureRealEstate',
    updates: (self: Meta, value: number) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategorySingle(
          self.parent,
          TagCategoryEnum.BuyoutExposureRealEstate,
          value
        );
      }
    },
  })
  buyoutExposureRealEstateTag?: number;

  @meta({
    alias: 'Buyout Exposure: Utilities',
    isInaudible: true,
    auditResponseProp: 'BuyoutExposureUtilities',
    updates: (self: Meta, value: number) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategorySingle(
          self.parent,
          TagCategoryEnum.BuyoutExposureUtilities,
          value
        );
      }
    },
  })
  buyoutExposureUtilitiesTag?: number;

  @meta({
    alias: 'GP-Led Transaction Type',
    isInaudible: true,
    auditResponseProp: 'GPLedTransactionType',
    updates: (self: Meta, value: number) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategorySingle(
          self.parent,
          TagCategoryEnum.GPLedTransactionType,
          value
        );
      }
    },
  })
  gpLedTransactionTypeTag?: number;

  @meta({
    alias: 'LP-Led Transaction Type',
    isInaudible: true,
    auditResponseProp: 'LPLedTransactionType',
    updates: (self: Meta, value: number) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategorySingle(
          self.parent,
          TagCategoryEnum.LPLedTransactionType,
          value
        );
      }
    },
  })
  lpLedTransactionTypeTag?: number;

  @meta({
    alias: 'Strategic Primaries Transaction Type',
    isInaudible: true,
    auditResponseProp: 'StrategicPrimariesTransactionType',
    updates: (self: Meta, value: number) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategorySingle(
          self.parent,
          TagCategoryEnum.StrategicPrimariesTransactionType,
          value
        );
      }
    },
  })
  strategicPrimariesTransactionTypeTag?: number;

  @meta({
    alias: 'Secondary Transaction Size: $0-$100 mn',
    isInaudible: true,
    auditResponseProp: 'SecondaryTransactionType0To100Mn',
    updates: (self: Meta, value: number) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategorySingle(
          self.parent,
          TagCategoryEnum.SecondaryTransactionType0To100Mn,
          value
        );
      }
    },
  })
  secondaryTransactionType0To100MnTag?: number;

  @meta({
    alias: 'Secondary Transaction Size: $100-$250 mn',
    isInaudible: true,
    auditResponseProp: 'SecondaryTransactionType100To250Mn',
    updates: (self: Meta, value: number) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategorySingle(
          self.parent,
          TagCategoryEnum.SecondaryTransactionType100To250Mn,
          value
        );
      }
    },
  })
  secondaryTransactionType100To250MnTag?: number;

  @meta({
    alias: 'Secondary Transaction Size: $250-$500 mn',
    isInaudible: true,
    auditResponseProp: 'SecondaryTransactionType250To500Mn',
    updates: (self: Meta, value: number) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategorySingle(
          self.parent,
          TagCategoryEnum.SecondaryTransactionType250To500Mn,
          value
        );
      }
    },
  })
  secondaryTransactionType250To500MnTag?: number;

  @meta({
    alias: 'Secondary Transaction Size: $500-$1,000 mn',
    isInaudible: true,
    auditResponseProp: 'SecondaryTransactionType500To1000Mn',
    updates: (self: Meta, value: number) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategorySingle(
          self.parent,
          TagCategoryEnum.SecondaryTransactionType500To1000Mn,
          value
        );
      }
    },
  })
  secondaryTransactionType500To1000MnTag?: number;

  @meta({
    alias: 'Secondary Transaction Size: $1,000+ mn',
    isInaudible: true,
    auditResponseProp: 'SecondaryTransactionType1000PlusMn',
    updates: (self: Meta, value: number) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategorySingle(
          self.parent,
          TagCategoryEnum.SecondaryTransactionType1000PlusMn,
          value
        );
      }
    },
  })
  secondaryTransactionType1000PlusMnTag?: number;

  applicablePECategories?: Array<number>;

  //#endregion

  //#region Real Asset

  @Type(() => Tag)
  @meta({
    alias: 'Infra Risk Profile',
    isInaudible: true,
    auditResponseProp: 'InfraRiskProfile',
    updates: (self: Meta, value: Array<Tag>) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategoryMulti(
          self.parent,
          TagCategoryEnum.InfraRiskProfile,
          value
        );
      }
    },
  })
  infraRiskProfileTags?: Array<Tag>;

  @Type(() => Tag)
  @meta({
    alias: 'RA Portfolio Focus',
    isInaudible: true,
    auditResponseProp: 'RAPortfolioFocus',
    updates: (self: Meta, value: Array<Tag>) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategoryMulti(
          self.parent,
          TagCategoryEnum.RAPortfolioFocus,
          value
        );
      }
    },
  })
  raPortfolioFocusTags?: Array<Tag>;

  applicableRACategories?: Array<number>;

  //#endregion

  //#region Real Estate

  @meta({
    alias: 'Primary Property Type',
    isInaudible: true,
    auditResponseProp: 'PrimaryPropertyType',
    updates: (self: Meta, value: number) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategorySingle(
          self.parent,
          TagCategoryEnum.PrimaryPropertyType,
          value
        );
      }
    },
  })
  primaryPropertyTypeTag?: number;

  @meta({
    alias: 'Manager Profile',
    isInaudible: true,
    auditResponseProp: 'ManagerProfile',
    updates: (self: Meta, value: number) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategorySingle(
          self.parent,
          TagCategoryEnum.ManagerProfile,
          value
        );
      }
    },
  })
  managerProfileTag?: number;

  @Type(() => Tag)
  @meta({
    alias: 'US Regional Focus',
    isInaudible: true,
    auditResponseProp: 'USRegionalFocus',
    updates: (self: Meta, value: Array<Tag>) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategoryMulti(
          self.parent,
          TagCategoryEnum.USRegionalFocus,
          value
        );
      }
    },
  })
  usRegionalFocusTags?: Array<Tag>;

  @Type(() => Tag)
  @meta({
    alias: 'Investment Strategy Details',
    isInaudible: true,
    auditResponseProp: 'InvestmentStrategyDetails',
    updates: (self: Meta, value: Array<Tag>) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategoryMulti(
          self.parent,
          TagCategoryEnum.InvestmentStrategyDetails,
          value
        );
      }
    },
  })
  investmentStrategyDetails?: Array<Tag>;

  @Type(() => Tag)
  @meta({
    alias: 'RE Portfolio Focus',
    isInaudible: true,
    auditResponseProp: 'REPortfolioFocus',
    updates: (self: Meta, value: Array<Tag>) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategoryMulti(
          self.parent,
          TagCategoryEnum.REPortfolioFocus,
          value
        );
      }
    },
  })
  rePortfolioFocus?: Array<Tag>;

  applicableRECategories?: Array<number>;

  //#endregion

  //#region Multi Asset Class

  @meta({
    alias: 'Market Cap LO',
    isInaudible: true,
    auditResponseProp: 'MarketCapLO',
    updates: (self: Meta, value: Array<Tag>) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategoryMulti(
          self.parent,
          TagCategoryEnum.MarketCapLO,
          value
        );
      }
    },
  })
  marketCapLoTags?: Array<Tag>;

  @meta({
    alias: 'Style LO',
    isInaudible: true,
    auditResponseProp: 'StyleLo',
    updates: (self: Meta, value: number) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategorySingle(
          self.parent,
          TagCategoryEnum.StyleLo,
          value
        );
      }
    },
  })
  styleLoTag?: number;

  @meta({
    alias: 'Approach Lo',
    isInaudible: true,
    auditResponseProp: 'ApproachLo',
    updates: (self: Meta, value: number) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategorySingle(
          self.parent,
          TagCategoryEnum.ApproachLo,
          value
        );
      }
    },
  })
  approachLoTag?: number;

  @meta({
    alias: 'Renewable Generation',
    isInaudible: true,
    auditResponseProp: 'RenewableGeneration',
    updates: (self: Meta, value: number) => {
      if (self.parent instanceof Fund) {
        TaxonomyMeta.syncEntityCategorySingle(
          self.parent,
          TagCategoryEnum.RenewableGeneration,
          value
        );
      }
    },
  })
  renewableGenerationTag?: number;

  applicableMultiAssetCategories?: Array<number>;

  //#endregion

  public syncMainHierarchy(fund: Fund) {
    this.entitySectors = plainToClass(
      EntitySector,
      Taxonomy.mainHierarchy?.map((sec) => ({
        sectorId: sec.sectorId,
        sector: sec.sector,
      }))
    );

    this.entityStrategies = plainToClass(
      EntityStrategy,
      Taxonomy.mainHierarchy
        ?.find((sec) => sec.sectorId === fund.sectorId)
        ?.strategies?.map((str) => ({
          strategyId: str.strategyId,
          strategy: str.strategy,
        }))
    );

    this.entitySubStrategies = Taxonomy.mainHierarchy
      ?.find((sec) => sec.sectorId === fund.sectorId)
      ?.strategies?.find(
        (str) => str.strategyId === fund.strategyId
      )?.substrategies;

    this.entityRegions = plainToClass(
      EntityRegion,
      Taxonomy.primaryRegions?.map((reg) => ({
        regionId: reg.id,
        region: reg.name,
      }))
    );
  }

  public syncTaxonomyMeta(fund: Fund) {
    this.syncMainHierarchy(fund);

    const entityTags: Array<EntityTag> = fund.quantumTags;

    //#region Structure

    this.internalPriorityLevel = TaxonomyMeta.syncMetaCategorySingle(
      entityTags,
      TagCategoryEnum.InternalPriorityLevel,
      Taxonomy.internalPriorityLevel
    );

    //#endregion

    //#region Other Sub-Strategy

    this.hfSubstrategyTags = TaxonomyMeta.syncMetaCategoryMulti(
      entityTags?.filter(
        (eTag) => eTag.tagCategory === TagCategoryEnum.HFSubstrategy
      ),
      Taxonomy.hfSubstrategy
    );
    this.pcSubstrategyTags = TaxonomyMeta.syncMetaCategoryMulti(
      entityTags?.filter(
        (eTag) => eTag.tagCategory === TagCategoryEnum.PCSubstrategy
      ),
      Taxonomy.pcSubstrategy
    );
    this.peSubstrategyTags = TaxonomyMeta.syncMetaCategoryMulti(
      entityTags?.filter(
        (eTag) => eTag.tagCategory === TagCategoryEnum.PESubstrategy
      ),
      Taxonomy.peSubstrategy
    );
    this.raSubstrategyTags = TaxonomyMeta.syncMetaCategoryMulti(
      entityTags?.filter(
        (eTag) => eTag.tagCategory === TagCategoryEnum.RASubstrategy
      ),
      Taxonomy.raSubstrategy
    );
    this.reSubstrategyTags = TaxonomyMeta.syncMetaCategoryMulti(
      entityTags?.filter(
        (eTag) => eTag.tagCategory === TagCategoryEnum.RESubstrategy
      ),
      Taxonomy.reSubstrategy
    );
    this.loSubstrategyTags = TaxonomyMeta.syncMetaCategoryMulti(
      entityTags?.filter(
        (eTag) => eTag.tagCategory === TagCategoryEnum.LongOnlySubstrategy
      ),
      Taxonomy.loSubstrategy
    );

    //#endregion

    //#region Geography

    this.geographyTag = TaxonomyMeta.syncMetaCategorySingle(
      entityTags,
      TagCategoryEnum.Geography,
      Taxonomy.geography
    );
    this.regionFocusTags = TaxonomyMeta.syncMetaCategoryMulti(
      entityTags?.filter(
        (eTag) => eTag.tagCategory === TagCategoryEnum.RegionFocus
      ),
      Taxonomy.regionFocus
    );
    this.countryFocusTags = TaxonomyMeta.syncMetaCategoryMulti(
      entityTags?.filter(
        (eTag) => eTag.tagCategory === TagCategoryEnum.CountryFocus
      ),
      Taxonomy.countryFocus
    );

    //#endregion

    //#region Industry

    this.industryTag = TaxonomyMeta.syncMetaCategoryMulti(
      entityTags?.filter(
        (eTag) => eTag.tagCategory === TagCategoryEnum.Industry
      ),
      Taxonomy.industry
    )[0]?.id;
    this.industrySectorTags = TaxonomyMeta.syncMetaCategoryMulti(
      entityTags?.filter(
        (eTag) => eTag.tagCategory === TagCategoryEnum.IndustrySector
      ),
      Taxonomy.industrySector
    );
    this.industryGroupTags = TaxonomyMeta.syncMetaCategoryMulti(
      entityTags?.filter(
        (eTag) => eTag.tagCategory === TagCategoryEnum.IndustryGroup
      ),
      Taxonomy.industryGroup
    );

    //#endregion

    //#region ESG

    this.esgTags = TaxonomyMeta.syncMetaCategoryMulti(
      entityTags?.filter((eTag) => eTag.tagCategory === TagCategoryEnum.ESG),
      Taxonomy.esg
    );
    this.wmdOwnershipTag = TaxonomyMeta.syncMetaCategoryMulti(
      entityTags?.filter(
        (eTag) =>
          eTag.tagCategory === TagCategoryEnum.WomanMinoriyDisabledOwnership
      ),
      Taxonomy.wmdOwnership
    )[0]?.id;
    this.esgRegulatedTags = TaxonomyMeta.syncMetaCategoryMulti(
      entityTags?.filter(
        (eTag) => eTag.tagCategory === TagCategoryEnum.ESGRegulated
      ),
      Taxonomy.esgRegulated
    );
    this.diversityCertificationsTag = TaxonomyMeta.syncMetaCategoryMulti(
      entityTags?.filter(
        (eTag) => eTag.tagCategory === TagCategoryEnum.DiversityCertifications
      ),
      Taxonomy.diversityCertifications
    );

    //#endregion

    //#region Hedge Fund

    this.marketCapHFTag = TaxonomyMeta.syncMetaCategoryMulti(
      entityTags?.filter(
        (eTag) => eTag.tagCategory === TagCategoryEnum.MarketCapHF
      ),
      Taxonomy.marketCapHF
    )[0]?.id;
    this.portfolioUtilityTag = TaxonomyMeta.syncMetaCategoryMulti(
      entityTags?.filter(
        (eTag) => eTag.tagCategory === TagCategoryEnum.PortfolioUtility
      ),
      Taxonomy.portfolioUtility
    )[0]?.id;
    this.primaryRiskDriverTag = TaxonomyMeta.syncMetaCategoryMulti(
      entityTags?.filter(
        (eTag) => eTag.tagCategory === TagCategoryEnum.PrimaryRiskDriver
      ),
      Taxonomy.primaryRiskDriver
    )[0]?.id;
    this.secondaryRiskDriverTag = TaxonomyMeta.syncMetaCategoryMulti(
      entityTags?.filter(
        (eTag) => eTag.tagCategory === TagCategoryEnum.SecondaryRiskDriver
      ),
      Taxonomy.secondaryRiskDriver
    )[0]?.id;
    this.stressEquityBetaTag = TaxonomyMeta.syncMetaCategoryMulti(
      entityTags?.filter(
        (eTag) => eTag.tagCategory === TagCategoryEnum.StressEquityBeta
      ),
      Taxonomy.stressEquityBeta
    )[0]?.id;
    this.hfPortfolioFocusTags = TaxonomyMeta.syncMetaCategoryMulti(
      entityTags?.filter(
        (eTag) => eTag.tagCategory === TagCategoryEnum.HFPortfolioFocus
      ),
      Taxonomy.hfPortfolioFocus
    );
    this.applicableHFCategories = TaxonomyMeta.syncApplicableStrategies(
      [
        ...Taxonomy.marketCapHF,
        ...Taxonomy.portfolioUtility,
        ...Taxonomy.primaryRiskDriver,
        ...Taxonomy.secondaryRiskDriver,
        ...Taxonomy.stressEquityBeta,
        ...Taxonomy.hfPortfolioFocus,
      ],
      fund
    );

    //#endregion

    //#region Private Credit

    this.originationTags = TaxonomyMeta.syncMetaCategoryMulti(
      entityTags?.filter(
        (eTag) => eTag.tagCategory === TagCategoryEnum.Origination
      ),
      Taxonomy.origination
    );
    this.seniorityTags = TaxonomyMeta.syncMetaCategoryMulti(
      entityTags?.filter(
        (eTag) => eTag.tagCategory === TagCategoryEnum.Seniority
      ),
      Taxonomy.seniority
    );
    this.incomeSourceTags = TaxonomyMeta.syncMetaCategoryMulti(
      entityTags?.filter(
        (eTag) => eTag.tagCategory === TagCategoryEnum.IncomeSource
      ),
      Taxonomy.incomeSource
    );
    this.underlyingAssetClassTags = TaxonomyMeta.syncMetaCategoryMulti(
      entityTags?.filter(
        (eTag) => eTag.tagCategory === TagCategoryEnum.UnderlyingAssetClass
      ),
      Taxonomy.underlyingAssetClass
    );
    this.marketCapPCTags = TaxonomyMeta.syncMetaCategoryMulti(
      entityTags?.filter(
        (eTag) => eTag.tagCategory === TagCategoryEnum.MarketCapPC
      ),
      Taxonomy.marketCapPC
    );
    this.borrowerStatusTags = TaxonomyMeta.syncMetaCategoryMulti(
      entityTags?.filter(
        (eTag) => eTag.tagCategory === TagCategoryEnum.BorrowerStatus
      ),
      Taxonomy.borrowerStatus
    );
    this.leverageTag = TaxonomyMeta.syncMetaCategoryMulti(
      entityTags?.filter(
        (eTag) => eTag.tagCategory === TagCategoryEnum.Leverage
      ),
      Taxonomy.leverage
    )[0]?.id;
    this.pcPortfolioFocusTags = TaxonomyMeta.syncMetaCategoryMulti(
      entityTags?.filter(
        (eTag) => eTag.tagCategory === TagCategoryEnum.PCPortfolioFocus
      ),
      Taxonomy.pcPortfolioFocus
    );
    this.applicablePCCategories = TaxonomyMeta.syncApplicableStrategies(
      [
        ...Taxonomy.origination,
        ...Taxonomy.seniority,
        ...Taxonomy.incomeSource,
        ...Taxonomy.underlyingAssetClass,
        ...Taxonomy.marketCapPC,
        ...Taxonomy.borrowerStatus,
        ...Taxonomy.leverage,
        ...Taxonomy.pcPortfolioFocus,
      ],
      fund
    );

    //#endregion

    //#region Private Equity

    this.marketCapPETags = TaxonomyMeta.syncMetaCategoryMulti(
      entityTags?.filter(
        (eTag) => eTag.tagCategory === TagCategoryEnum.MarketCapPE
      ),
      Taxonomy.marketCapPE
    );
    this.dealStrategyTags = TaxonomyMeta.syncMetaCategoryMulti(
      entityTags?.filter(
        (eTag) => eTag.tagCategory === TagCategoryEnum.DealStrategy
      ),
      Taxonomy.dealStrategy
    );
    this.ownershipTags = TaxonomyMeta.syncMetaCategoryMulti(
      entityTags?.filter(
        (eTag) => eTag.tagCategory === TagCategoryEnum.Ownership
      ),
      Taxonomy.ownership
    );
    this.pePortfolioFocusTags = TaxonomyMeta.syncMetaCategoryMulti(
      entityTags?.filter(
        (eTag) => eTag.tagCategory === TagCategoryEnum.PEPortfolioFocus
      ),
      Taxonomy.pePortfolioFocus
    );
    this.buyoutFundStyleTag = TaxonomyMeta.syncMetaCategorySingle(
      entityTags,
      TagCategoryEnum.BuyoutFundStyle,
      Taxonomy.buyoutFundStyle
    );
    this.coInvestmentActivityTag = TaxonomyMeta.syncMetaCategorySingle(
      entityTags,
      TagCategoryEnum.CoinvestmentActivity,
      Taxonomy.coInvestmentActivity
    );
    this.priorityLevelTag = TaxonomyMeta.syncMetaCategorySingle(
      entityTags,
      TagCategoryEnum.PriorityLevel,
      Taxonomy.priorityLevel
    );
    this.gpLedTransactionTypesTags = TaxonomyMeta.syncMetaCategoryMulti(
      entityTags?.filter(
        (eTag) => eTag.tagCategory === TagCategoryEnum.GPLedTransactionTypes
      ),
      Taxonomy.gpLedTransactionTypes
    );
    this.secondaryInvestmentStageTags = TaxonomyMeta.syncMetaCategoryMulti(
      entityTags?.filter(
        (eTag) => eTag.tagCategory === TagCategoryEnum.SecondaryInvestmentStage
      ),
      Taxonomy.secondaryInvestmentStage
    );
    this.ventureCapitalAndGrowthEquityIndustryExposureTags =
      TaxonomyMeta.syncMetaCategoryMulti(
        entityTags?.filter(
          (eTag) =>
            eTag.tagCategory ===
            TagCategoryEnum.VentureCapitalAndGrowthEquityIndustryExposure
        ),
        Taxonomy.ventureCapitalAndGrowthEquityIndustryExposure
      );
    this.growthEquityTargetedRevenueGrowthTags =
      TaxonomyMeta.syncMetaCategoryMulti(
        entityTags?.filter(
          (eTag) =>
            eTag.tagCategory ===
            TagCategoryEnum.GrowthEquityTargetedRevenueGrowth
        ),
        Taxonomy.growthEquityTargetedRevenueGrowth
      );
    this.growthEquityTargetedRevenueTags = TaxonomyMeta.syncMetaCategoryMulti(
      entityTags?.filter(
        (eTag) =>
          eTag.tagCategory === TagCategoryEnum.GrowthEquityTargetedRevenue
      ),
      Taxonomy.growthEquityTargetedRevenue
    );
    this.growthEquityTargetedProfitabilityTags =
      TaxonomyMeta.syncMetaCategoryMulti(
        entityTags?.filter(
          (eTag) =>
            eTag.tagCategory ===
            TagCategoryEnum.GrowthEquityTargetedProfitability
        ),
        Taxonomy.growthEquityTargetedProfitability
      );
    this.gpStakesTargetedAssetsUnderManagementTags =
      TaxonomyMeta.syncMetaCategoryMulti(
        entityTags?.filter(
          (eTag) =>
            eTag.tagCategory ===
            TagCategoryEnum.GPStakesTargetedAssetsUnderManagement
        ),
        Taxonomy.gpStakesTargetedAssetsUnderManagement
      );
    this.northAmericaExposureTag = TaxonomyMeta.syncMetaCategorySingle(
      entityTags,
      TagCategoryEnum.NorthAmericaExposure,
      Taxonomy.northAmericaExposure
    );
    this.europeExposureTag = TaxonomyMeta.syncMetaCategorySingle(
      entityTags,
      TagCategoryEnum.EuropeExposure,
      Taxonomy.europeExposure
    );
    this.asiaAndPacificExposureTag = TaxonomyMeta.syncMetaCategorySingle(
      entityTags,
      TagCategoryEnum.AsiaAndPacificExposure,
      Taxonomy.asiaAndPacificExposure
    );
    this.southOrLatinAmericaExposureTag = TaxonomyMeta.syncMetaCategorySingle(
      entityTags,
      TagCategoryEnum.SouthOrLatinAmericaExposure,
      Taxonomy.southOrLatinAmericaExposure
    );
    this.africaExposureTag = TaxonomyMeta.syncMetaCategorySingle(
      entityTags,
      TagCategoryEnum.AfricaExposure,
      Taxonomy.africaExposure
    );
    this.buyoutExposureAerospaceAndDefenseTag =
      TaxonomyMeta.syncMetaCategorySingle(
        entityTags,
        TagCategoryEnum.BuyoutExposureAerospaceAndDefense,
        Taxonomy.buyoutExposureAerospaceAndDefense
      );
    this.buyoutExposureBusinessServicesTag =
      TaxonomyMeta.syncMetaCategorySingle(
        entityTags,
        TagCategoryEnum.BuyoutExposureBusinessServices,
        Taxonomy.buyoutExposureBusinessServices
      );
    this.buyoutExposureConsumerTag = TaxonomyMeta.syncMetaCategorySingle(
      entityTags,
      TagCategoryEnum.BuyoutExposureConsumer,
      Taxonomy.buyoutExposureConsumer
    );
    this.buyoutExposureCommunicationsAndMediaTag =
      TaxonomyMeta.syncMetaCategorySingle(
        entityTags,
        TagCategoryEnum.BuyoutExposureCommunicationsAndMedia,
        Taxonomy.buyoutExposureCommunicationsAndMedia
      );
    this.buyoutExposureEducationTag = TaxonomyMeta.syncMetaCategorySingle(
      entityTags,
      TagCategoryEnum.BuyoutExposureEducation,
      Taxonomy.buyoutExposureEducation
    );
    this.buyoutExposureEnergyTag = TaxonomyMeta.syncMetaCategorySingle(
      entityTags,
      TagCategoryEnum.BuyoutExposureEnergy,
      Taxonomy.buyoutExposureEnergy
    );
    this.buyoutExposureFinancialsTag = TaxonomyMeta.syncMetaCategorySingle(
      entityTags,
      TagCategoryEnum.BuyoutExposureFinancials,
      Taxonomy.buyoutExposureFinancials
    );
    this.buyoutExposureGovernmentServicesTag =
      TaxonomyMeta.syncMetaCategorySingle(
        entityTags,
        TagCategoryEnum.BuyoutExposureGovernmentServices,
        Taxonomy.buyoutExposureGovernmentServices
      );
    this.buyoutExposureHealthCareTag = TaxonomyMeta.syncMetaCategorySingle(
      entityTags,
      TagCategoryEnum.BuyoutExposureHealthCare,
      Taxonomy.buyoutExposureHealthCare
    );
    this.buyoutExposureIndustrialsTag = TaxonomyMeta.syncMetaCategorySingle(
      entityTags,
      TagCategoryEnum.BuyoutExposureIndustrials,
      Taxonomy.buyoutExposureIndustrials
    );
    this.buyoutExposureMaterialsTag = TaxonomyMeta.syncMetaCategorySingle(
      entityTags,
      TagCategoryEnum.BuyoutExposureMaterials,
      Taxonomy.buyoutExposureMaterials
    );
    this.buyoutExposureSportsTag = TaxonomyMeta.syncMetaCategorySingle(
      entityTags,
      TagCategoryEnum.BuyoutExposureSports,
      Taxonomy.buyoutExposureSports
    );
    this.buyoutExposureTechnologyHardwareTag =
      TaxonomyMeta.syncMetaCategorySingle(
        entityTags,
        TagCategoryEnum.BuyoutExposureTechnologyHardware,
        Taxonomy.buyoutExposureTechnologyHardware
      );
    this.buyoutExposureTechnologySoftwareTag =
      TaxonomyMeta.syncMetaCategorySingle(
        entityTags,
        TagCategoryEnum.BuyoutExposureTechnologySoftware,
        Taxonomy.buyoutExposureTechnologySoftware
      );
    this.buyoutExposureTransportationAndLogisticsTag =
      TaxonomyMeta.syncMetaCategorySingle(
        entityTags,
        TagCategoryEnum.BuyoutExposureTransportationAndLogistics,
        Taxonomy.buyoutExposureTransportationAndLogistics
      );
    this.buyoutExposureRealEstateTag = TaxonomyMeta.syncMetaCategorySingle(
      entityTags,
      TagCategoryEnum.BuyoutExposureRealEstate,
      Taxonomy.buyoutExposureRealEstate
    );
    this.buyoutExposureUtilitiesTag = TaxonomyMeta.syncMetaCategorySingle(
      entityTags,
      TagCategoryEnum.BuyoutExposureUtilities,
      Taxonomy.buyoutExposureUtilities
    );
    this.gpLedTransactionTypeTag = TaxonomyMeta.syncMetaCategorySingle(
      entityTags,
      TagCategoryEnum.GPLedTransactionType,
      Taxonomy.gpLedTransactionType
    );
    this.lpLedTransactionTypeTag = TaxonomyMeta.syncMetaCategorySingle(
      entityTags,
      TagCategoryEnum.LPLedTransactionType,
      Taxonomy.lpLedTransactionType
    );
    this.strategicPrimariesTransactionTypeTag =
      TaxonomyMeta.syncMetaCategorySingle(
        entityTags,
        TagCategoryEnum.StrategicPrimariesTransactionType,
        Taxonomy.strategicPrimariesTransactionType
      );
    this.secondaryTransactionType0To100MnTag =
      TaxonomyMeta.syncMetaCategorySingle(
        entityTags,
        TagCategoryEnum.SecondaryTransactionType0To100Mn,
        Taxonomy.secondaryTransactionType0To100Mn
      );
    this.secondaryTransactionType100To250MnTag =
      TaxonomyMeta.syncMetaCategorySingle(
        entityTags,
        TagCategoryEnum.SecondaryTransactionType100To250Mn,
        Taxonomy.secondaryTransactionType100To250Mn
      );
    this.secondaryTransactionType250To500MnTag =
      TaxonomyMeta.syncMetaCategorySingle(
        entityTags,
        TagCategoryEnum.SecondaryTransactionType250To500Mn,
        Taxonomy.secondaryTransactionType250To500Mn
      );
    this.secondaryTransactionType500To1000MnTag =
      TaxonomyMeta.syncMetaCategorySingle(
        entityTags,
        TagCategoryEnum.SecondaryTransactionType500To1000Mn,
        Taxonomy.secondaryTransactionType500To1000Mn
      );
    this.secondaryTransactionType1000PlusMnTag =
      TaxonomyMeta.syncMetaCategorySingle(
        entityTags,
        TagCategoryEnum.SecondaryTransactionType1000PlusMn,
        Taxonomy.secondaryTransactionType1000PlusMn
      );

    this.applicablePECategories = TaxonomyMeta.syncApplicableStrategies(
      [
        ...Taxonomy.marketCapPE,
        ...Taxonomy.dealStrategy,
        ...Taxonomy.pePortfolioFocus,
        ...Taxonomy.ownership,
        ...Taxonomy.buyoutFundStyle,
        ...Taxonomy.coInvestmentActivity,
        ...Taxonomy.priorityLevel,
        ...Taxonomy.gpLedTransactionTypes,
        ...Taxonomy.secondaryInvestmentStage,
        ...Taxonomy.ventureCapitalAndGrowthEquityIndustryExposure,
        ...Taxonomy.growthEquityTargetedRevenueGrowth,
        ...Taxonomy.growthEquityTargetedRevenue,
        ...Taxonomy.growthEquityTargetedProfitability,
        ...Taxonomy.gpStakesTargetedAssetsUnderManagement,
        ...Taxonomy.northAmericaExposure,
        ...Taxonomy.europeExposure,
        ...Taxonomy.asiaAndPacificExposure,
        ...Taxonomy.southOrLatinAmericaExposure,
        ...Taxonomy.africaExposure,
        ...Taxonomy.buyoutExposureAerospaceAndDefense,
        ...Taxonomy.buyoutExposureBusinessServices,
        ...Taxonomy.buyoutExposureConsumer,
        ...Taxonomy.buyoutExposureCommunicationsAndMedia,
        ...Taxonomy.buyoutExposureEducation,
        ...Taxonomy.buyoutExposureEnergy,
        ...Taxonomy.buyoutExposureFinancials,
        ...Taxonomy.buyoutExposureGovernmentServices,
        ...Taxonomy.buyoutExposureHealthCare,
        ...Taxonomy.buyoutExposureIndustrials,
        ...Taxonomy.buyoutExposureMaterials,
        ...Taxonomy.buyoutExposureSports,
        ...Taxonomy.buyoutExposureTechnologyHardware,
        ...Taxonomy.buyoutExposureTechnologySoftware,
        ...Taxonomy.buyoutExposureTransportationAndLogistics,
        ...Taxonomy.buyoutExposureRealEstate,
        ...Taxonomy.buyoutExposureUtilities,
        ...Taxonomy.gpLedTransactionType,
        ...Taxonomy.lpLedTransactionType,
        ...Taxonomy.strategicPrimariesTransactionType,
        ...Taxonomy.secondaryTransactionType0To100Mn,
        ...Taxonomy.secondaryTransactionType100To250Mn,
        ...Taxonomy.secondaryTransactionType250To500Mn,
        ...Taxonomy.secondaryTransactionType500To1000Mn,
        ...Taxonomy.secondaryTransactionType1000PlusMn,
      ],
      fund
    );

    //#endregion

    //#region Real Asset

    this.infraRiskProfileTags = TaxonomyMeta.syncMetaCategoryMulti(
      entityTags?.filter(
        (eTag) => eTag.tagCategory === TagCategoryEnum.InfraRiskProfile
      ),
      Taxonomy.infraRiskProfile
    );
    this.raPortfolioFocusTags = TaxonomyMeta.syncMetaCategoryMulti(
      entityTags?.filter(
        (eTag) => eTag.tagCategory === TagCategoryEnum.RAPortfolioFocus
      ),
      Taxonomy.raPortfolioFocus
    );
    this.applicableRACategories = TaxonomyMeta.syncApplicableStrategies(
      [...Taxonomy.infraRiskProfile, ...Taxonomy.raPortfolioFocus],
      fund
    );

    //#endregion

    //#region Real Estate

    this.primaryPropertyTypeTag = TaxonomyMeta.syncMetaCategoryMulti(
      entityTags?.filter(
        (eTag) => eTag.tagCategory === TagCategoryEnum.PrimaryPropertyType
      ),
      Taxonomy.primaryPropertyType
    )[0]?.id;
    this.managerProfileTag = TaxonomyMeta.syncMetaCategoryMulti(
      entityTags?.filter(
        (eTag) => eTag.tagCategory === TagCategoryEnum.ManagerProfile
      ),
      Taxonomy.managerProfile
    )[0]?.id;
    this.usRegionalFocusTags = TaxonomyMeta.syncMetaCategoryMulti(
      entityTags?.filter(
        (eTag) => eTag.tagCategory === TagCategoryEnum.USRegionalFocus
      ),
      Taxonomy.usRegionalFocus
    );
    this.investmentStrategyDetails = TaxonomyMeta.syncMetaCategoryMulti(
      entityTags?.filter(
        (eTag) => eTag.tagCategory === TagCategoryEnum.InvestmentStrategyDetails
      ),
      Taxonomy.investmentStrategyDetails
    );
    this.rePortfolioFocus = TaxonomyMeta.syncMetaCategoryMulti(
      entityTags?.filter(
        (eTag) => eTag.tagCategory === TagCategoryEnum.REPortfolioFocus
      ),
      Taxonomy.rePortfolioFocus
    );
    this.applicableRECategories = TaxonomyMeta.syncApplicableStrategies(
      [
        ...Taxonomy.primaryPropertyType,
        ...Taxonomy.managerProfile,
        ...Taxonomy.usRegionalFocus,
        ...Taxonomy.investmentStrategyDetails,
        ...Taxonomy.rePortfolioFocus,
      ],
      fund
    );

    //#endregion

    //#region Multi Asset Class

    this.marketCapLoTags = TaxonomyMeta.syncMetaCategoryMulti(
      entityTags?.filter(
        (eTag) => eTag.tagCategory === TagCategoryEnum.MarketCapLO
      ),
      Taxonomy.marketCapLO
    );

    this.styleLoTag = TaxonomyMeta.syncMetaCategoryMulti(
      entityTags?.filter(
        (eTag) => eTag.tagCategory === TagCategoryEnum.StyleLo
      ),
      Taxonomy.styleLo
    )[0]?.id;

    this.approachLoTag = TaxonomyMeta.syncMetaCategoryMulti(
      entityTags?.filter(
        (eTag) => eTag.tagCategory === TagCategoryEnum.ApproachLo
      ),
      Taxonomy.approachLo
    )[0]?.id;

    this.renewableGenerationTag = TaxonomyMeta.syncMetaCategoryMulti(
      entityTags?.filter(
        (eTag) => eTag.tagCategory === TagCategoryEnum.RenewableGeneration
      ),
      Taxonomy.renewableGeneration
    )[0]?.id;

    this.applicableMultiAssetCategories = TaxonomyMeta.syncApplicableStrategies(
      [
        ...Taxonomy.marketCapLO,
        ...Taxonomy.styleLo,
        ...Taxonomy.approachLo,
        ...Taxonomy.renewableGeneration,
      ],
      fund
    );

    //#endregion
  }

  static syncApplicableStrategies(tagPool: Array<Tag>, fund: Fund) {
    let applicableCategories: Array<number>;

    tagPool.forEach((tag) => {
      if (
        tag.applicableStrategies.some(
          (appstr) => appstr.strategyId === fund.strategyId
        )
      ) {
        if (!applicableCategories?.includes(tag.categoryId)) {
          applicableCategories = [
            ...(applicableCategories ?? []),
            tag.categoryId,
          ];
        }
      }
    });

    return applicableCategories;
  }

  static syncEntityCategorySingle(
    fund: Fund,
    tagCategory: TagCategoryEnum,
    tagId: number
  ) {
    fund.quantumTags = fund.quantumTags?.filter(
      (qt) => qt.tagCategory !== tagCategory
    );

    if (tagId) {
      const entityTag = new EntityTag();
      entityTag.tagId = tagId;
      entityTag.tagCategory = tagCategory;
      fund.quantumTags?.push(entityTag);
    }
  }

  static syncEntityCategoryMulti(
    fund: Fund,
    tagCategory: TagCategoryEnum,
    tags: Array<Tag>
  ) {
    fund.quantumTags = fund.quantumTags?.filter(
      (qt) => qt.tagCategory !== tagCategory
    );
    if (tags?.length > 0) {
      tags.forEach((tag) => {
        if (
          tag &&
          !fund.quantumTags.some((entityTag) => entityTag.tagId === tag.id)
        ) {
          const entityTag = new EntityTag();
          entityTag.tagId = tag.id;
          entityTag.tagCategory = tag.categoryId;
          fund.quantumTags.push(entityTag);
        }
      });
    }
  }

  static syncMetaCategorySingle(
    entityTags: Array<EntityTag>,
    tagCategory: TagCategoryEnum,
    metaTags: Array<Tag>
  ): number {
    const entityTag = entityTags.find(
      (entityTag) => entityTag.tagCategory === tagCategory
    );
    if (!entityTag) {
      return null;
    }
    return metaTags.find(
      (tag) => tag.id === entityTag.tagId && tag.categoryId === tagCategory
    )?.id;
  }

  static syncMetaCategoryMulti(
    entityTags: Array<EntityTag>,
    metaTags: Array<Tag>
  ): Array<Tag> {
    return entityTags?.map((entityTag) =>
      metaTags?.find((tag) => tag.id === entityTag?.tagId)
    );
  }

  auditSourceField = '-';
  auditAsOfField = '-';
  auditURL = 'basicdata/fund/audit/{0}/quantumtaxonomytags';
  auditURLParams = ['parent.fundId@model'];
}

export class EntitySector {
  sectorId?: number;
  sector?: string;
}

export class EntityStrategy {
  strategyId?: number;
  strategy?: string;
}

export class EntityRegion {
  regionId?: number;
  region: string;
}

export class Sector extends EntitySector {
  assetClassId?: number;
  assetClass?: string;

  @Type(() => Strategy)
  strategies?: Array<Strategy>;
}

export class Strategy extends EntityStrategy {
  @Type(() => SubStrategy)
  substrategies?: Array<SubStrategy>;
}

export class SubStrategy {
  substrategyId?: number;
  substrategy?: string;
}

export class Tag {
  id?: number;
  name?: string;
  categoryId?: number;
  categoryName?: string;

  buckets?: Array<string>;

  @Type(() => TagStrategy)
  applicableStrategies?: Array<TagStrategy>;

  @Type(() => SectorStrategyLink)
  sectorStrategyLinks?: Array<SectorStrategyLink>;

  static linksToBucket(
    sectorStrategyLinks?: Array<SectorStrategyLink>
  ): Array<string> {
    return sectorStrategyLinks?.map((ssl) => `${ssl.sector} - ${ssl.strategy}`);
  }
}

export class SectorStrategyLink {
  sector?: string;
  strategy?: string;
}

export class TagStrategy {
  strategyId?: number;
  strategyName?: string;
  sectorName?: string;
}

export class EntityTag extends Meta {
  /* id?: number = 0;
    fundId?: number; */
  tagId?: number;
  tagCategory?: TagCategoryEnum;
}
