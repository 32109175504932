//#region System Enums

export enum ValidationType {
  Required,
  Requires,
  RequiredIf,
}

export enum ChangesStrategy {
  First = 'firstTime',
  Each = 'eachTime',
  NonFirst = 'nonFirst',
}

export enum AvailableViewsEnum {
  LegalDocTeam = 1,
  OnePager,
  Co_Investment,
}

export namespace AvailableViewsEnum {
  export function toKeyValue() {
    const keys = Object.keys(AvailableViewsEnum);
    return keys
      .slice(keys.length / 2, keys.length - 1)
      .map((key, index) => ({
        value: key.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/_/g, '-'),
        key: AvailableViewsEnum[key],
      }))
      .sort((a, b) => (a.value > b.value ? 1 : -1)); //.filter( key => key.key !== 3);
  }
}

//#endregion

//#region Taxonomy

export enum TagCategoryEnum {
  AssetClass = 1,
  Sector,
  Strategy,
  SubStrategy,
  MAXType,
  MAXUniverse,
  CrossStrategyTags,
  MarketCapHF,
  PortfolioUtility,
  PrimaryRiskDriver,
  SecondaryRiskDriver,
  StressEquityBeta,
  HFPortfolioFocus,
  Origination,
  Seniority,
  IncomeSource,
  UnderlyingAssetClass,
  MarketCapPC,
  BorrowerStatus,
  Leverage,
  PCPortfolioFocus,
  MarketCapPE,
  DealStrategy,
  Ownership,
  PEPortfolioFocus,
  InfraRiskProfile,
  RAPortfolioFocus,
  PrimaryPropertyType,
  ManagerProfile,
  USRegionalFocus,
  InvestmentStrategyDetails,
  REPortfolioFocus,
  Geography,
  RegionFocus,
  CountryFocus,
  Industry,
  IndustrySector,
  IndustryGroup,
  ESG,
  WomanMinoriyDisabledOwnership,
  ESGRegulated,

  BuyoutFundStyle = 49,
  CoinvestmentActivity = 50,
  PriorityLevel = 51,
  GPLedTransactionTypes = 52,
  SecondaryInvestmentStage = 53,
  VentureCapitalAndGrowthEquityIndustryExposure = 54,
  GrowthEquityTargetedRevenueGrowth = 55,
  GrowthEquityTargetedRevenue = 56,
  GrowthEquityTargetedProfitability = 57,
  GPStakesTargetedAssetsUnderManagement = 58,
  NorthAmericaExposure = 59,
  EuropeExposure = 60,
  AsiaAndPacificExposure = 61,
  SouthOrLatinAmericaExposure = 62,
  AfricaExposure = 63,
  BuyoutExposureAerospaceAndDefense = 64,
  BuyoutExposureBusinessServices = 65,
  BuyoutExposureConsumer = 66,
  BuyoutExposureCommunicationsAndMedia = 67,
  BuyoutExposureEducation = 68,
  BuyoutExposureEnergy = 69,
  BuyoutExposureFinancials = 70,
  BuyoutExposureGovernmentServices = 71,
  BuyoutExposureHealthCare = 72,
  BuyoutExposureIndustrials = 73,
  BuyoutExposureMaterials = 74,
  BuyoutExposureSports = 75,
  BuyoutExposureTechnologyHardware = 76,
  BuyoutExposureTechnologySoftware = 77,
  BuyoutExposureTransportationAndLogistics = 78,
  BuyoutExposureRealEstate = 79,
  BuyoutExposureUtilities = 80,
  GPLedTransactionType = 81,
  LPLedTransactionType = 82,
  StrategicPrimariesTransactionType = 83,
  SecondaryTransactionType0To100Mn = 84,
  SecondaryTransactionType100To250Mn = 85,
  SecondaryTransactionType250To500Mn = 86,
  SecondaryTransactionType500To1000Mn = 87,
  SecondaryTransactionType1000PlusMn = 88,
  AssetClassSubstrategy = 42,
  HFSubstrategy = 43,
  PCSubstrategy = 44,
  PESubstrategy = 45,
  RASubstrategy = 46,
  RESubstrategy = 47,
  LongOnlySubstrategy = 48,

  InternalPriorityLevel = 89,
  DiversityCertifications = 90,
  PrimaryRegion = 91,
  MarketCapLO = 93,
  StyleLo = 94,
  ApproachLo = 95,
  RenewableGeneration = 96,
}

//#endregion

export enum AUMRelationEnum {
  None,
  Parent,
  Children,
  GrandChildren,
}

export enum DataSourceEnum {
  PPM = 1,
  /* LPA, */
  ManagerMaterials = 3,
  AksiaTemplate,
  /* ManagerPortal,  */
  Pitchbook = 6,
  Monthly__QuarterlyLetter,
  Monthly__QuarterlyRiskReport,
  Factsheet,
  PerformanceDocument,
  AumDocument,
  FinancialStatement,
  Website,
  Inbox,
  FundCreationTemplate,
  PPM_and_Supplement_s_,
  PPM_Supplement,
  DraftLPA,
  ExecutedLPA,
  ExecutedLPA_and_LPA_Amendment_s_,
  LPA_Amendment,
  LLC_Agreement,
  TermSheet,
  Aksia__Manager,
  SideLetter__Memo,
  Non___StandardLetter = 26,
  DDQ = 27,
  InceptionPlaceholder = 28,
  IMA,
  OrgChart,
  FormADV,
  Form10Q,
  Media,
}
export namespace DataSourceEnum {
  export function toKeyValue() {
    const keys = Object.keys(DataSourceEnum);
    return keys
      .slice(keys.length / 2, keys.length - 1)
      .map((key, index) => ({
        value: key
          .replace(/_s_/g, '(s)')
          .replace(/___/g, '-')
          .replace(/__/g, ' / ')
          .replace(/_/g, ' ')
          .replace(/([a-z])([A-Z])/g, '$1 $2'),
        key: DataSourceEnum[key],
      }))
      .sort((a, b) => (a.value > b.value ? 1 : -1));
  }
}

export enum CurrencyEnum {
  USD = 1,
  GBP,
  EUR,
  JPY,
  MXN,
  INR,
  BRL,
  CAD,
  AUD,
  CHF,
  DKK,
  NOK,
  SEK,
  ZAR,
  HKD,
  CNY,
  KRW,
  SGD,
}
export namespace CurrencyEnum {
  export function toKeyValue() {
    const keys = Object.keys(CurrencyEnum);
    return keys
      .slice(keys.length / 2 - 1, keys.length - 2)
      .map((key, index) => ({
        value: key,
        key: CurrencyEnum[key],
      }))
      .sort((a, b) => (a.value > b.value ? 1 : -1));
  }
  export function toValueValue() {
    const keys = Object.keys(CurrencyEnum);
    return keys
      .slice(keys.length / 2 - 1, keys.length - 2)
      .map((key, index) => ({
        value: key,
        key: key,
      }))
      .sort((a, b) => (a.value > b.value ? 1 : -1));
  }
}

export enum FundStructureEnum {
  Stand_alone = 1,
  Master_feeder = 2,
  MultipleMasters = 6,
  Intermediate__Master = 3,
  UmbrellaStand_alone = 4,
  UmbrellaMaster_feeder = 5,
}
export namespace FundStructureEnum {
  export function toKeyValue() {
    const keys = Object.keys(FundStructureEnum);
    return keys.slice(keys.length / 2, keys.length - 1).map((key, index) => ({
      value: key
        .replace(/__/g, '/')
        .replace('_', '-')
        .replace(/([a-z])([A-Z])/g, '$1 $2'),
      key: FundStructureEnum[key],
    }));
  }
}

export enum LiquidityStructureEnum {
  OpenEnd = 1,
  ClosedEnd,
  Hybrid,
}
export namespace LiquidityStructureEnum {
  export function toKeyValue() {
    const keys = Object.keys(LiquidityStructureEnum);
    return keys.slice(keys.length / 2, keys.length - 1).map((key, index) => ({
      value: key.replace(/([a-z])([A-Z])/g, '$1 $2'),
      key: LiquidityStructureEnum[key],
    }));
  }
}

export enum VEntityTypeEnum {
  CommingledFund = 1,
  Fund_of_One,
  SeparateAccount,
  Co_Investment,
  DirectInvestment,
  Secondary_Single,
  Secondary_Portfolio,
  Fund__of__Funds,
  ManagedAccountCompos,
  CarveOut,
  LiquidatingSPV___Sidepocket,
  AksiaMFCs___IFCs,
  JointVenture,
  N___A,
  GP_Stake,
  SidecarOverflow,
  ContinuationFund,
}

export namespace VEntityTypeEnum {
  export function toKeyValue() {
    const keys = Object.keys(VEntityTypeEnum);
    return keys.slice(keys.length / 2, keys.length - 1).map((key, index) => ({
      value: key
        .replace(/___/g, '/')
        .replace(/__/g, ' ')
        .replace(/_/g, ' - ')
        .replace(/([a-z])([A-Z])/g, '$1 $2'),
      key: VEntityTypeEnum[key],
    }));
  }
}

export enum CompanyStatusEnum {
  Active = 1,
  Liquidating,
  Dissolved,
}

export namespace CompanyStatusEnum {
  export function toKeyValue() {
    const keys = Object.keys(CompanyStatusEnum);
    return keys.slice(keys.length / 2, keys.length - 1).map((key, index) => ({
      value: key.replace(/([a-z])([A-Z])/g, '$1 $2'),
      key: CompanyStatusEnum[key],
    }));
  }
}

export enum ProgramLiquidityStructureEnum {
  Unknown = 0,
  OpenEnd = 1,
  ClosedEnd = 2,
  Hybrid = 3,
}
export namespace ProgramLiquidityStructureEnum {
  export function toKeyValue() {
    const keys = Object.keys(ProgramLiquidityStructureEnum);
    return keys.slice(keys.length / 2, keys.length - 1).map((key, index) => ({
      value: key.replace(/([a-z])([A-Z])/g, '$1 $2'),
      key: ProgramLiquidityStructureEnum[key],
    }));
  }
}

export enum HedgeFundStructureEnum {
  NeverLaunched = 1,
  Pre_Marketing,
  NotMapped,
  Liquidating,
  FullyLiquidated,
  Active,
}
export namespace HedgeFundStructureEnum {
  export function toKeyValue() {
    const keys = Object.keys(HedgeFundStructureEnum);
    return keys.slice(keys.length / 2, keys.length - 1).map((key, index) => ({
      value: key.replace(/([a-z])([A-Z])/g, '$1 $2').replace('_', '-'),
      key: HedgeFundStructureEnum[key],
    }));
  }
}

export enum RedemptionDayEnum {
  FirstDay = 'FirstDay',
  LastDay = 'LastDay',
  FirstDayFollowingAnniversary = 'FirstDayFollowingAnniversary',
  LastDayFollowingAnniversary = 'LastDayFollowingAnniversary',
  SubscriptionDay = 'SubscriptionDay',
}
export namespace RedemptionDayEnum {
  export function toKeyValue() {
    const keys = Object.keys(RedemptionDayEnum);
    return keys
      .map((key, index) => ({
        value: key.replace(/([a-z])([A-Z])/g, '$1 $2'),
        key: RedemptionDayEnum[key],
      }))
      .slice(0, keys.length - 1);
  }
}

export enum FundLevelsEnum {
  MasterFund = 1,
  Fund,
  Class,
  Investor,
  Investor_Fund,
  Investor_MasterFund,
}
export namespace FundLevelsEnum {
  export function toKeyValue() {
    const keys = Object.keys(FundLevelsEnum);
    return keys.slice(keys.length / 2, keys.length - 1).map((key, index) => ({
      value: key.replace(/_/g, '/').replace(/([a-z])([A-Z])/g, '$1 $2'),
      key: FundLevelsEnum[key],
    }));
  }
}

export enum LockUpEnum {
  Hard = 1,
  Soft,
}
export namespace LockUpEnum {
  export function toKeyValue() {
    const keys = Object.keys(LockUpEnum);
    return keys.slice(keys.length / 2, keys.length - 1).map((key, index) => ({
      value: key,
      key: LockUpEnum[key],
    }));
  }
}

export enum CalendarUnitEnum {
  Years = 1,
  Semesters = 8,
  Quarters = 2,
  Months = 3,
  Weeks = 4,
  Days = 5,
  Custom = 6,
}
export namespace CalendarUnitEnum {
  export function toKeyValue() {
    const keys = Object.keys(CalendarUnitEnum);
    return keys.slice(keys.length / 2, keys.length - 1).map((key, index) => ({
      value: key,
      key: CalendarUnitEnum[key],
    }));
  }
}

export enum CalendarFrequencyEnum {
  Annually = 1,
  Semi_annually,
  Quarterly,
  Monthly,
  Weekly,
  Bi_weekly,
  Daily,
}
export namespace CalendarFrequencyEnum {
  export function toKeyValue() {
    const keys = Object.keys(CalendarFrequencyEnum);
    return keys.slice(keys.length / 2, keys.length - 1).map((key, index) => ({
      value: key.replace(/_/g, '-'),
      key: CalendarFrequencyEnum[key],
    }));
  }
}

export enum ActiveTimePeriodEnum {
  CalendarDays = 1,
  BusinessDays,
}
export namespace ActiveTimePeriodEnum {
  export function toKeyValue() {
    const keys = Object.keys(ActiveTimePeriodEnum);
    return keys.slice(keys.length / 2, keys.length - 1).map((key, index) => ({
      value: key.replace(/([a-z])([A-Z])/g, '$1 $2'),
      key: ActiveTimePeriodEnum[key],
    }));
  }
}

export enum SimpleAnswerEnum {
  Yes = 1,
  No,
  NotSpecified,
}
export namespace SimpleAnswerEnum {
  export function toKeyValue() {
    const keys = Object.keys(SimpleAnswerEnum);
    return keys.slice(keys.length / 2, keys.length - 1).map((key, index) => ({
      value: key.replace(/([a-z])([A-Z])/g, '$1 $2'),
      key: SimpleAnswerEnum[key],
    }));
  }
}

export enum SimpleAnswerPassEnum {
  Yes = 1,
  No,
  PassThrough,
}
export namespace SimpleAnswerPassEnum {
  export function toKeyValue() {
    const keys = Object.keys(SimpleAnswerPassEnum);
    return keys.slice(keys.length / 2, keys.length - 1).map((key, index) => ({
      value: key.replace(/([a-z])([A-Z])/g, '$1 $2'),
      key: SimpleAnswerPassEnum[key],
    }));
  }
}

export enum MgmtFeeFrequencyEnum {
  MonthlyInAdvance = 1,
  MonthlyInArrears,
  QuarterlyInAdvance,
  QuarterlyInArrears,
  Semi_annuallyInAdvance,
  Semi_annuallyInArrears,
  AnnuallyInAdvance,
  AnnuallyInArrears,
  Other,
}
export namespace MgmtFeeFrequencyEnum {
  export function toKeyValue() {
    const keys = Object.keys(MgmtFeeFrequencyEnum);
    return keys.slice(keys.length / 2, keys.length - 1).map((key, index) => ({
      value: key.replace(/_/g, '-').replace(/([a-z])([A-Z])/g, '$1 $2'),
      key: MgmtFeeFrequencyEnum[key],
    }));
  }
}

export enum MgmtFeeRateTypeEnum {
  SingleRate = 1,
  BlendedRate,
}

export namespace MgmtFeeRateTypeEnum {
  export function toKeyValue() {
    const keys = Object.keys(MgmtFeeRateTypeEnum);
    return keys.slice(keys.length / 2, keys.length - 1).map((key, index) => ({
      value: key.replace(/([a-z])([A-Z])/g, '$1 $2'),
      key: MgmtFeeRateTypeEnum[key],
    }));
  }
}

export enum IncFeeRateTypeEnum {
  SingleRate = 1,
  TieredRate,
  SlidingScaleRate,
}

export namespace IncFeeRateTypeEnum {
  export function toKeyValue() {
    const keys = Object.keys(IncFeeRateTypeEnum);
    return keys.slice(keys.length / 2, keys.length - 1).map((key, index) => ({
      value: key.replace(/([a-z])([A-Z])/g, '$1 $2'),
      key: IncFeeRateTypeEnum[key],
    }));
  }
}

export enum HighWaterMarkEnum {
  StandardHWM = 1,
  Non_StandardHWM,
  NoHWM,
}
export namespace HighWaterMarkEnum {
  export function toKeyValue() {
    const keys = Object.keys(HighWaterMarkEnum);
    return keys.slice(keys.length / 2, keys.length - 1).map((key, index) => ({
      value: key.replace(/_/g, '-').replace(/([a-z])([A-Z])/g, '$1 $2'),
      key: HighWaterMarkEnum[key],
    }));
  }
}

export enum RateIndexEnum {
  Index = 1,
  FixedRate,
  IndexPlusFixedRate,
}
export namespace RateIndexEnum {
  export function toKeyValue() {
    const keys = Object.keys(RateIndexEnum);
    return keys.slice(keys.length / 2, keys.length - 1).map((key, index) => ({
      value: key.replace(/([a-z])([A-Z])/g, '$1 $2'),
      key: RateIndexEnum[key],
    }));
  }
}

export enum HurdleIndexEnum {
  MSCI = 1,
  Libor,
  Euribor,
  WSJ_prime_rate,
  JP_Morgan_prime_rate,
  Canadian_bank_prime_rate,
  S_and_P_500,
  Three__month_T__bill_rate,
  Russell_2000,
  FDTR,
  Other,
}
export namespace HurdleIndexEnum {
  export function toKeyValue() {
    const keys = Object.keys(HurdleIndexEnum);
    return keys.slice(keys.length / 2, keys.length - 1).map((key, index) => ({
      value: key
        .replace(/_and_/g, '&')
        .replace(/Three/g, '3')
        .replace(/__/g, '-')
        .replace(/_/g, ' '),
      key: HurdleIndexEnum[key],
    }));
  }
}

//Private Field Enums
export enum CloseDateTypeEnum {
  Estimate = 1,
  Actual,
}
export namespace CloseDateTypeEnum {
  export function toKeyValue() {
    const keys = Object.keys(CloseDateTypeEnum);
    return keys.slice(keys.length / 2, keys.length - 1).map((key, index) => ({
      value: key,
      key: CloseDateTypeEnum[key],
    }));
  }
}

export enum CommitmentsTypeEnum {
  Interim = 1,
  Final,
}

export namespace CommitmentsTypeEnum {
  export function toKeyValue() {
    const keys = Object.keys(CommitmentsTypeEnum);
    return keys.slice(keys.length / 2, keys.length - 1).map((key, index) => ({
      value: key,
      key: CommitmentsTypeEnum[key],
    }));
  }
}

export enum ProgramStatusEnum {
  Fundraising = 1,
  NotFundraising,
  Pre_Marketing,
  OpenEnded,
  NeverLaunched,
  WindingDownLiquidating,
  FundraisingSMAsOnly,
  FullyRealized,
  FundraisingPaused,
  N__A,
}

export namespace ProgramStatusEnum {
  export function toKeyValue() {
    const keys = Object.keys(ProgramStatusEnum);
    return keys.slice(keys.length / 2, keys.length - 1).map((key, index) => ({
      value: key
        .replace(/__/g, '/')
        .replace(/_/g, '-')
        .replace(/([a-z])([A-Z])/g, '$1 $2'),
      key: ProgramStatusEnum[key],
    }));
  }
}

export enum FundStatusEnum {
  Pre_launch__Pre_Investing = 1,
  Active__Investing,
  Liquidating__Harvesting,
  FullyLiquidated__Realized,
  NeverLaunched,
}

export namespace FundStatusEnum {
  export function toKeyValue() {
    const keys = Object.keys(FundStatusEnum);
    return keys.slice(keys.length / 2, keys.length - 1).map((key, index) => ({
      value: key
        .replace(/__/g, ' / ')
        .replace(/_/g, '-')
        .replace(/([a-z])([A-Z])/g, '$1 $2'),
      key: FundStatusEnum[key],
    }));
  }
}

export enum FundRaisingStatusEnum {
  Fundraising = 1,
  NotFundraising,
}

export namespace FundRaisingStatusEnum {
  export function toKeyValue() {
    const keys = Object.keys(FundRaisingStatusEnum);
    return keys.slice(keys.length / 2, keys.length - 1).map((key, index) => ({
      value: key.replace(/([a-z])([A-Z])/g, '$1 $2'),
      key: FundRaisingStatusEnum[key],
    }));
  }
}

export enum ThirdPartyProviderEnum {
  Pitchbook,
  Preqin,
  Other,
}

export namespace ThirdPartyProviderEnum {
  export function toKeyValue() {
    const keys = Object.keys(ThirdPartyProviderEnum);
    return keys.slice(keys.length / 2, keys.length - 1).map((key, index) => ({
      value: key.replace(/([a-z])([A-Z])/g, '$1 $2'),
      key: ThirdPartyProviderEnum[key],
    }));
  }
}

export enum MonthEnum {
  January = 1,
  February,
  March,
  April,
  May,
  June,
  July,
  August,
  September,
  October,
  November,
  December,
}

export namespace MonthEnum {
  export function toKeyValue() {
    const keys = Object.keys(MonthEnum);
    return keys.slice(keys.length / 2, keys.length - 1).map((key, index) => ({
      value: key,
      key: MonthEnum[key],
    }));
  }
}

export enum IPSizeGroupEnum {
  Micro = 1,
  Small,
  Mid,
  Large,
  Mega,
}

export namespace IPSizeGroupEnum {
  export function toKeyValue() {
    const keys = Object.keys(IPSizeGroupEnum);
    return keys.slice(keys.length / 2, keys.length - 1).map((key, index) => ({
      value: key,
      key: IPSizeGroupEnum[key],
    }));
  }
}

export enum SourceEnum {
  ExistingPCRelationship = 1,
  ExistingHFRelationship,
  PlacementAgent,
  ExternalDatabase,
  InboundGPContact,
  IndustryResearch,
  ConferenceIndustryEvent,
  Other,
  FollowOnFund,
  ExistingGPRelationship,
}

export namespace SourceEnum {
  export function toKeyValue() {
    const keys = Object.keys(SourceEnum);
    return keys.slice(keys.length / 2, keys.length - 1).map((key, index) => ({
      value: key.replace(/([a-z])([A-Z])/g, '$1 $2'),
      key: SourceEnum[key],
    }));
  }
}

export enum EligibilityEnum {
  Onshore = 1,
  Offshore,
  Onshore_and_Offshore,
}

export namespace EligibilityEnum {
  export function toKeyValue() {
    const keys = Object.keys(EligibilityEnum);
    return keys.slice(keys.length / 2, keys.length - 1).map((key, index) => ({
      value: key.replace(/_and_/g, ' & '),
      key: EligibilityEnum[key],
    }));
  }
}

export enum CoInvestPolicyEnum {
  Yes_Fee = 1,
  Yes_NoFee,
  No,
}

export namespace CoInvestPolicyEnum {
  export function toKeyValue() {
    const keys = Object.keys(CoInvestPolicyEnum);
    return keys.slice(keys.length / 2, keys.length - 1).map((key, index) => ({
      value: key.replace(/_/g, ' - ').replace(/([a-z])([A-Z])/g, '$1 $2'),
      key: CoInvestPolicyEnum[key],
    }));
  }
}

export enum LPACEnum {
  No = 1,
  Mandatory,
  NonMandatory,
}

export namespace LPACEnum {
  export function toKeyValue() {
    const keys = Object.keys(LPACEnum);
    return keys.slice(keys.length / 2, keys.length - 1).map((key, index) => ({
      value: key.replace(/([a-z])([A-Z])/g, '$1 $2'),
      key: LPACEnum[key],
    }));
  }
}

export enum ClosedEndStageEnum {
  SeedStage = 1,
  EarlyStage,
  LateStage,
}

export namespace ClosedEndStageEnum {
  export function toKeyValue() {
    const keys = Object.keys(ClosedEndStageEnum);
    return keys.slice(keys.length / 2, keys.length - 1).map((key, index) => ({
      value: key.replace(/([a-z])([A-Z])/g, '$1 $2'),
      key: ClosedEndStageEnum[key],
    }));
  }
}

export enum ClosedEndTagsEnum {
  SpecialSituationBalanceSheet = 1,
  SpecialSituationOperations,
  LongDated,
  Impact,
  PledgeFunds,
}

export namespace ClosedEndTagsEnum {
  export function toKeyValue() {
    const keys = Object.keys(ClosedEndTagsEnum);
    return keys.slice(keys.length / 2, keys.length - 1).map((key, index) => ({
      value: key.replace(/([a-z])([A-Z])/g, '$1 $2'),
      key: ClosedEndTagsEnum[key],
    }));
  }
}

export enum ClosedEndCountryEnum {
  Generalist = 1,
  RegionSpecialist,
}

export namespace ClosedEndCountryEnum {
  export function toKeyValue() {
    const keys = Object.keys(ClosedEndCountryEnum);
    return keys.slice(keys.length / 2, keys.length - 1).map((key, index) => ({
      value: key.replace(/([a-z])([A-Z])/g, '$1 $2'),
      key: ClosedEndCountryEnum[key],
    }));
  }
}

export enum ClosedEndIndustryEnum {
  Generalist = 1,
  IndustrySpecialist,
}

export namespace ClosedEndIndustryEnum {
  export function toKeyValue() {
    const keys = Object.keys(ClosedEndIndustryEnum);
    return keys.slice(keys.length / 2, keys.length - 1).map((key, index) => ({
      value: key.replace(/([a-z])([A-Z])/g, '$1 $2'),
      key: ClosedEndIndustryEnum[key],
    }));
  }
}

export enum PlacementAgentEnum {
  None = 'None',
  AresManagementCapitalMarketsLLC = 'Ares Management Capital Markets LLC',
  AsanteCapital = 'Asante Capital',
  AtlanticPacificCapital = 'Atlantic-Pacific Capital',
  CampbellLutyensAndCo = 'Campbell Lutyens & Co',
  CreditSuissePrivateFundGroup = 'Credit Suisse Private Fund Group',
  EatonPartnersLLC = 'Eaton Partners LLC',
  Evercore = 'Evercore',
  FirstAvenuePartnersLLC = 'First Avenue Partners LLC',
  GallatinCapitalLLC = 'Gallatin Capital LLC',
  GreenhillAndCo = 'Greenhill & Co',
  HodesWeill = 'Hodes Weill',
  LazardPrivateCapitalAdvisory = 'Lazard (Private Capital Advisory)',
  McamGroup = 'MCAM Group',
  MercuryCapitalAdvisorsLLC = 'Mercury Capital Advisors LLC',
  OakpointAdvisors = 'Oakpoint Advisors',
  ParkHillGroup = 'Park Hill Group',
  ProbitasPartners = 'Probitas Partners',
  RedePartners = 'Rede Partners',
  UBSPrivateFundsGroup = 'UBS Private Funds Group',
  Other = 'Other',
}

export namespace PlacementAgentEnum {
  export function toKeyValue() {
    const keys = Object.keys(PlacementAgentEnum);
    return keys.slice(0, keys.length - 1).map((key, index) => ({
      value: PlacementAgentEnum[key],
      key: key,
    })); //.sort( (a,b) => a.value > b.value ? 1 : -1);
  }
}

export enum LiquidityTierEnum {
  Tier_1__Liquid___ = 1,
  Tier_2__Borrowing_Capacity___,
  Tier_3__Semi____Liquid___,
  Tier_4__Limitied_Liquidity_but_Some_Secondary_Activity___,
  Tier_5__Illiquid___,
}

export namespace LiquidityTierEnum {
  export function toKeyValue() {
    const keys = Object.keys(LiquidityTierEnum);
    return keys.slice(keys.length / 2, keys.length - 1).map((key, index) => ({
      value: key
        .replace(/____/g, '-')
        .replace(/___/g, ')')
        .replace(/__/g, ' (')
        .replace(/_/g, ' '),
      key: LiquidityTierEnum[key],
    }));
  }
}

export enum ContractualDateTypeEnum {
  Relative_to_First_Close_Date = 1,
  Relative_to_Effective_Date = 4,
  Fixed_date = 2,
  Relative_to_other_date = 3,
}
export namespace ContractualDateTypeEnum {
  export function toKeyValue() {
    const keys = Object.keys(ContractualDateTypeEnum);
    return keys.slice(keys.length / 2, keys.length - 1).map((key, index) => ({
      value: key.replace(/_/g, ' ').replace(/([a-z])([A-Z])/g, '$1 $2'),
      key: ContractualDateTypeEnum[key],
    }));
  }
}

export enum PeriodTypeEnum {
  FollowingInvestmentPeriodEnd = 1,
  FollowingFirstClose = 2,
  FollowingFinalClose = 3,
  FollowingEffectiveDate = 5,
  FollowingOtherDate = 4,
  FollowingLiquidationOfInvestments = 9,
  FollowingFirstDrawdown = 7,
  FollowingFirstInvestment = 8,
  FollowingFeeAccrual = 6,
}
export namespace PeriodTypeEnum {
  export function toKeyValue() {
    const keys = Object.keys(PeriodTypeEnum);
    return keys.slice(keys.length / 2, keys.length - 1).map((key, index) => ({
      value: key.replace(/_/g, ' ').replace(/([a-z])([A-Z])/g, '$1 $2'),
      key: PeriodTypeEnum[key],
    }));
  }
}

export enum ExtensionTypeEnum {
  in_GPs_Discretion = 1,
  with_LPAC_consent,
  with_LP_consent,
  with_LP__LPAC_consent,
}
export namespace ExtensionTypeEnum {
  export function toKeyValue() {
    const keys = Object.keys(ExtensionTypeEnum);
    return keys.slice(keys.length / 2, keys.length - 1).map((key, index) => ({
      value: key.replace(/__/g, '/').replace(/_/g, ' '),
      key: ExtensionTypeEnum[key],
    }));
  }
}

export enum MinGPCommitmentEnum {
  Percentage = 1,
  Amount,
  Greater_of,
  Lesser_of,
}
export namespace MinGPCommitmentEnum {
  export function toKeyValue() {
    const keys = Object.keys(MinGPCommitmentEnum);
    return keys.slice(keys.length / 2, keys.length - 1).map((key, index) => ({
      value: key.replace(/_/g, ' '),
      key: MinGPCommitmentEnum[key],
    }));
  }
}

export enum SubClInterestIndexEnum {
  Libor = 2,
  Euribor,
  WSJ_prime_rate,
  JP_Morgan_prime_rate,
  Canadian_bank_prime_rate,
  Citibank_prime_rate,
  Bank_of_America_prime_rate,
  Other,
}
export namespace SubClInterestIndexEnum {
  export function toKeyValue() {
    const keys = Object.keys(SubClInterestIndexEnum);
    return keys.slice(keys.length / 2, keys.length - 1).map((key, index) => ({
      value: key
        .replace(/_and_/g, '&')
        .replace(/Three/g, '3')
        .replace(/__/g, '-')
        .replace(/_/g, ' '),
      key: SubClInterestIndexEnum[key],
    }));
  }
}

//Add to Wiki
export enum RecyclingTimeLimitEnum {
  Only_for_amounts_received_during_the_investment_period = 1,
  Only_for_investments_realized_within_a_specific_timeframe,
}

export namespace RecyclingTimeLimitEnum {
  export function toKeyValue() {
    const keys = Object.keys(RecyclingTimeLimitEnum);
    return keys.slice(keys.length / 2, keys.length - 1).map((key, index) => ({
      value: key.replace(/_/g, ' '),
      key: RecyclingTimeLimitEnum[key],
    }));
  }
}

//Add to Wiki
export enum RecyclingProceedsLimitEnum {
  Only_principal_amount_of_investments = 1,
  Only_amounts_drawn_down_for_expenses,
  Other,
}

export namespace RecyclingProceedsLimitEnum {
  export function toKeyValue() {
    const keys = Object.keys(RecyclingProceedsLimitEnum);
    return keys.slice(keys.length / 2, keys.length - 1).map((key, index) => ({
      value: key.replace(/_/g, ' '),
      key: RecyclingProceedsLimitEnum[key],
    }));
  }
}

export enum LpClawbackCalculatedOnEnum {
  Commitments = 1,
  DistributionsReceived,
}

export namespace LpClawbackCalculatedOnEnum {
  export function toKeyValue() {
    const keys = Object.keys(LpClawbackCalculatedOnEnum);
    return keys.slice(keys.length / 2, keys.length - 1).map((key, index) => ({
      value: key.replace(/([a-z])([A-Z])/g, '$1 $2'),
      key: LpClawbackCalculatedOnEnum[key],
    }));
  }
}

export enum LpClawbackTimeLimitFromEnum {
  Date_of_distribution = 1,
  Date_of_fund__s_dissolution,
  Date_LPs_are_notified,
}

export namespace LpClawbackTimeLimitFromEnum {
  export function toKeyValue() {
    const keys = Object.keys(LpClawbackTimeLimitFromEnum);
    return keys.slice(keys.length / 2, keys.length - 1).map((key, index) => ({
      value: key.replace(/__/g, "'").replace(/_/g, ' '),
      key: LpClawbackTimeLimitFromEnum[key],
    }));
  }
}

export enum LeverageTypeEnum {
  Fund = 1,
  Asset,
}

export enum LeverageBasisEnum {
  calculated_on_committed_capital = 1,
  calculated_on_NAV,
  calculated_on_other_amount,
  debt_to_equity__D___E____,
  net_debt___EBITDA,
  leverage_to_value__LTV____,
  leverage_to_cost__LTC____,
}
export namespace LeverageBasisEnum {
  export function toKeyValue() {
    const keys = Object.keys(LeverageBasisEnum);
    return keys.slice(keys.length / 2, keys.length - 1).map((key, index) => ({
      value: key
        .replace(/____/g, ')')
        .replace(/___/g, '/')
        .replace(/__/g, ' (')
        .replace(/_/g, ' '),
      key: LeverageBasisEnum[key],
    }));
  }
}

export enum FeeBaseEnum {
  Committed = 1,
  Invested,
  GrossAssets = 7,
}

export namespace FeeBaseEnum {
  export function toKeyValue() {
    const keys = Object.keys(FeeBaseEnum);
    return keys.slice(keys.length / 2, keys.length - 1).map((key, index) => ({
      value: key.replace(/([a-z])([A-Z])/g, '$1 $2'),
      key: FeeBaseEnum[key],
    }));
  }
}

//Update Wiki
export enum MgmtFeeFromEnum {
  First_Close_Date = 1,
  Final_Close_Date = 10,
  Fee_Accrual = 11,
  First_DrawDown_Due_Date = 2,
  Effective_Date = 3,
  End_of_Investment_Period = 4,
  Earlier_of_Investment_Period_End_and_Date_Successor_Fund_is_Raised = 5,
  End_of_Term___without_extensions__ = 6,
  First_Term_Extension = 7,
  Second_Term_Extension = 8,
  Other = 9,
  Fund____s_Dissolution = 12,
}

export namespace MgmtFeeFromEnum {
  export function toKeyValue() {
    const keys = Object.keys(MgmtFeeFromEnum);
    return keys.slice(keys.length / 2, keys.length - 1).map((key, index) => ({
      value: key
        .replace(/_d_/g, '')
        .replace(/____/g, "'")
        .replace(/___/g, ' (')
        .replace(/__/g, ') ')
        .replace(/_/g, ' '),
      key: MgmtFeeFromEnum[key],
    }));
  }
}

//Update Wiki
export enum MgmtFeeToEnum {
  End_of_Investment_Period = 1,
  Earlier_of_Investment_Period_End_and_Date_Successor_Fund_is_Raised,
  End_of_Term___without_extensions__,
  First_Term_Extension,
  Second_Term_Extension,
  Fund____s_Dissolution,
  Other,
}

export namespace MgmtFeeToEnum {
  export function toKeyValue() {
    const keys = Object.keys(MgmtFeeToEnum);
    return keys.slice(keys.length / 2, keys.length - 1).map((key, index) => ({
      value: key
        .replace(/_d_/g, '')
        .replace(/____/g, "'")
        .replace(/___/g, ' (')
        .replace(/__/g, ') ')
        .replace(/_/g, ' '),
      key: MgmtFeeToEnum[key],
    }));
  }
}

export enum MgmtFeeCalcOnEnum {
  committed_capital = 1,
  actively_invested_capital = 2,
  actively_invested_capital_plus_unfunded_commitments = 5,
  total_invested_capital = 6,
  total_invested_capital_plus_unfunded_commitments = 14,
  NAV = 3,
  GAV = 7,
  GAV_plus_unfunded_commitments = 9,
  unfunded_commitments = 15,
  lesser_of_NAV_and_committed_capital = 12,
  lesser_of_NAV_and_total_invested_capital = 13,
  lesser_of_NAV_and_actively_invested_capital = 4,
  lesser_of_actively_invested_capital_and_committed_capital = 10,
  lesser_of_total_invested_capital_and_committed_capital = 11,
  other_amount = 8,
}

export namespace MgmtFeeCalcOnEnum {
  export function toKeyValue() {
    const keys = Object.keys(MgmtFeeCalcOnEnum);
    return keys.slice(keys.length / 2, keys.length - 1).map((key, index) => ({
      value: key.replace(/_/g, ' '),
      key: MgmtFeeCalcOnEnum[key],
    }));
  }
}

export enum MgmtFeePeriodTypeEnum {
  calculated_on_committed_capital = 1,
  calculated_on_invested_capital,
  calculated_on_NAV,
  calculated_on_lesser_of_NAV_and_invested_capital,
  calculated_on_invested_capital_plus_unfunded_commitments,
  calculated_on_other_amount,
}
export namespace MgmtFeePeriodTypeEnum {
  export function toKeyValue() {
    const keys = Object.keys(MgmtFeePeriodTypeEnum);
    return keys.slice(keys.length / 2, keys.length - 1).map((key, index) => ({
      value: key.replace(/_/g, ' '),
      key: MgmtFeePeriodTypeEnum[key],
    }));
  }
}

export enum PassThroughManagementFeeTypeEnum {
  Full = 1,
  Partial,
}

export namespace PassThroughManagementFeeTypeEnum {
  export function toKeyValue() {
    const keys = Object.keys(PassThroughManagementFeeTypeEnum);
    return keys.slice(keys.length / 2, keys.length - 1).map((key, index) => ({
      value: key.replace(/([a-z])([A-Z])/g, '$1 $2'),
      key: PassThroughManagementFeeTypeEnum[key],
    }));
  }
}

export enum LeverageUseEnum {
  Yes___all_leverage_facilities__ = 1,
  Yes___sub_lines_only__,
  No,
}

export namespace LeverageUseEnum {
  export function toKeyValue() {
    const keys = Object.keys(LeverageUseEnum);
    return keys.slice(keys.length / 2, keys.length - 1).map((key, index) => ({
      value: key.replace(/___/g, ' (').replace(/__/g, ')').replace(/_/g, ' '),
      key: LeverageUseEnum[key],
    }));
  }
}

export enum CarriedInterestRateTypeEnum {
  SingleRate = 1,
  TieredRate,
}

export namespace CarriedInterestRateTypeEnum {
  export function toKeyValue() {
    const keys = Object.keys(CarriedInterestRateTypeEnum);
    return keys.slice(keys.length / 2, keys.length - 1).map((key, index) => ({
      value: key.replace(/([a-z])([A-Z])/g, '$1 $2'),
      key: CarriedInterestRateTypeEnum[key],
    }));
  }
}

export enum CarriedInterestTieredBaseEnum {
  PreferredReturn = 1,
  Multiple,
  PreferredReturn_and_Multiple,
}

export namespace CarriedInterestTieredBaseEnum {
  export function toKeyValue() {
    const keys = Object.keys(CarriedInterestTieredBaseEnum);
    return keys.slice(keys.length / 2, keys.length - 1).map((key, index) => ({
      value: key.replace(/_and_/g, ' & ').replace(/([a-z])([A-Z])/g, '$1 $2'),
      key: CarriedInterestTieredBaseEnum[key],
    }));
  }
}

export enum PrefReturnEnum {
  No = 1,
  YesWithCatch_Up,
  YesWithoutCatch_Up,
}
export namespace PrefReturnEnum {
  export function toKeyValue() {
    const keys = Object.keys(PrefReturnEnum);
    return keys.slice(keys.length / 2, keys.length - 1).map((key, index) => ({
      value: key.replace(/_/g, '-').replace(/([a-z])([A-Z])/g, '$1 $2'),
      key: PrefReturnEnum[key],
    }));
  }
}

export enum WaterfallEnum {
  European___Fund_Level__ = 2,
  American___Deal_by_Deal__ = 1,
  American___Fair_Value_Test__ = 4,
  Other = 3,
}
export namespace WaterfallEnum {
  export function toKeyValue() {
    const keys = Object.keys(WaterfallEnum);
    return keys.slice(keys.length / 2, keys.length - 1).map((key, index) => ({
      value: key
        .replace(/___/g, ' (')
        .replace(/__/g, ')')
        .replace(/_/g, ' ')
        .replace(/([a-z])([A-Z])/g, '$1 $2'),
      key: WaterfallEnum[key],
    }));
  }
}

//Update Wiki
export enum ClawbackGuaranteeEnum {
  No = 1,
  Yes__by_carry_recipients_severally,
  Yes__by_carry_recipients_jointly,
  Yes__by_management,
  Yes__by_management_and_carry_recipients_severally,
  Yes__by_management_or_carry_recipients_severally,
}

export namespace ClawbackGuaranteeEnum {
  export function toKeyValue() {
    const keys = Object.keys(ClawbackGuaranteeEnum);
    return keys.slice(keys.length / 2, keys.length - 1).map((key, index) => ({
      value: key.replace(/_/g, ' '),
      key: ClawbackGuaranteeEnum[key],
    }));
  }
}

export enum RecyclingEnum {
  No = 1,
  Yes_Principal,
  Yes_FullRecycling,
}
export namespace RecyclingEnum {
  export function toKeyValue() {
    const keys = Object.keys(RecyclingEnum);
    return keys.slice(keys.length / 2, keys.length - 1).map((key, index) => ({
      value: key.replace(/_/g, ' - ').replace(/([a-z])([A-Z])/g, '$1 $2'),
      key: RecyclingEnum[key],
    }));
  }
}

export enum DiscountTypeEnum {
  General = 1,
  /* Aksia_Proprietary, */ Aksia_Clients = 3,
}
export namespace DiscountTypeEnum {
  export function toKeyValue() {
    const keys = Object.keys(DiscountTypeEnum);
    return keys.slice(keys.length / 2, keys.length - 1).map((key, index) => ({
      value: key.replace(/_/g, '-').replace(/([a-z])([A-Z])/g, '$1 $2'),
      key: DiscountTypeEnum[key],
    }));
  }
}

export enum StreamSourceEnum {
  PPM = 1,
  Pitchbook = 6,
  MonthlyOrQuarterlyLetter = 7,
  MonthlyOrQuarterlyRiskReport = 8,
  Factsheet = 9,
  PerformanceDocument = 10,
  FinancialStatement = 12,
  Website = 13,
  DDQ = 27,
  Form10Q = 32,
  Media = 33,
  EmailManager = 101,
  Phone = 102,
  OnsiteMeeting = 103,
}

export namespace StreamSourceEnum {
  export function toKeyValue() {
    const keys = Object.keys(StreamSourceEnum);
    return keys
      .slice(keys.length / 2, keys.length - 1)
      .map((key, index) => ({
        value: key.replace(/([a-z])([A-Z])/g, '$1 $2'),
        key: StreamSourceEnum[key],
      }))
      .sort((a, b) => (a.value > b.value ? 1 : -1));
  }
}

export enum AUMSourceEnum {
  EmailReportingInbox = 1,
  EmailPersonal = 2,
  InvestorLetter = 3,
  RiskReport = 4,
  FactSheet = 5,
  PitchBook = 6,
  PerformanceDocument = 7,
  Website = 8,
  Phone = 9,
  DDQ = 10,
  DataProvider = 11,
  AumDocument = 12,
  PPM = 13,
  FinancialStatements = 14,
  AdministratorTransparency = 15,
  OnsiteMeeting = 16,
  AksiaTemplate = 17,
  QuarterlyDataRequestTemplate = 18,
  OrgChart,
  FormADV,
  Form10Q = 32,
  Media,
}

export namespace AUMSourceEnum {
  export function toKeyValue() {
    const keys = Object.keys(AUMSourceEnum);
    return keys
      .slice(keys.length / 2, keys.length - 1)
      .map((key, index) => ({
        value: key.replace(/([a-z])([A-Z])/g, '$1 $2'),
        key: AUMSourceEnum[key],
      }))
      .sort((a, b) => (a.value > b.value ? 1 : -1));
  }
}

export enum PublicReturnSourceEnum {
  EmailReportingInbox = 1,
  EmailPersonal = 2,
  InvestorLetter = 3,
  RiskReport = 4,
  FactSheet = 5,
  PitchBook = 6,
  PerformanceDocument = 7,
  WebSite = 8,
  Phone = 9,
  DDQ = 10,
  DataProvider = 11,
  AumDocument = 12,
  PPM = 13,
  FinancialStatements = 14,
  AdministratorTransparency = 15,
  OnsiteMeeting = 16,
  ESGReport = 17,
  QuarterlyDataRequestTemplate = 18,
  RMB = 19,
  FormADV = 20,
  Form10Q = 32,
  AksiaTemplate = 101,
}

export namespace PublicReturnSourceEnum {
  export function toKeyValue() {
    const keys = Object.keys(PublicReturnSourceEnum);
    return keys
      .slice(keys.length / 2, keys.length - 1)
      .map((key, index) => ({
        value: key.replace(/([a-z])([A-Z])/g, '$1 $2'),
        key: PublicReturnSourceEnum[key],
      }))
      .sort((a, b) => (a.value > b.value ? 1 : -1));
  }
}

export enum PublicReturnFeeEnum {
  Net = 1,
  Gross,
  GrossMgmtFee,
  GrossPerfFee,
}

export namespace PublicReturnFeeEnum {
  export function toKeyValue() {
    const keys = Object.keys(PublicReturnFeeEnum);
    return keys
      .slice(keys.length / 2, keys.length - 1)
      .map((key, index) => ({
        value: key.replace(/([a-z])([A-Z])/g, '$1 $2'),
        key: PublicReturnFeeEnum[key],
      }))
      .sort((a, b) => (a.value > b.value ? 1 : -1));
  }
}

export enum PublicReturnGeneralClassificationEnum {
  Fund = 3,
  InvestmentProgram = 2,
  Master = 10,
  Composite = 20,
  InternalOnly = 25,
  FundOfOne = 26,
  CarveOut = 21,
  Backtested = 23,
}

export namespace PublicReturnGeneralClassificationEnum {
  export function toKeyValue() {
    const keys = Object.keys(PublicReturnGeneralClassificationEnum);
    return keys
      .slice(keys.length / 2, keys.length - 1)
      .map((key, index) => ({
        value: key.replace(/([a-z])([A-Z])/g, '$1 $2'),
        key: PublicReturnGeneralClassificationEnum[key],
      }))
      .sort((a, b) => (a.value > b.value ? 1 : -1));
  }
}

export enum DataProviderEnum {
  MAX = 1,
  Ostrako_O2 = 2,
}

export enum StreamPeriodicity {
  Daily = 1,
  Weekly,
  Monthly,
  Quarterly,
}

export enum EntityTypeEnum {
  ManagementCompany = 1,
  Program = 2,
  Fund = 3,
  Shareclass = 7,
  AUM = 5,
}

export namespace EntityTypeEnum {
  export function toKeyValue() {
    const keys = Object.keys(EntityTypeEnum);
    return keys.slice(keys.length / 2, keys.length - 1).map((key, index) => ({
      value: key.replace(/([a-z])([A-Z])/g, '$1 $2'),
      key: EntityTypeEnum[key],
    }));
  }
}

export enum AccountingMethodEnum {
  USGAAP = 1,
  IFRS,
  UKGAAP,
  LuxembourgGAAP,
  IrishGAAP,
  FrenchGAAP,
  JapaneseGAAP,
  IndianGAAP,
  Other,
}

export namespace AccountingMethodEnum {
  export function toKeyValue() {
    const keys = Object.keys(AccountingMethodEnum);
    return keys.slice(keys.length / 2, keys.length - 1).map((key, index) => ({
      value: key.replace(/([a-z])([A-Z])/g, '$1 $2'),
      key: AccountingMethodEnum[key],
    }));
  }
}

//#region Co-Investment

export enum AcquisitionTypeEnum {
  ThirdPartyAcquisition = 1,
  Recapitalization,
}

export namespace AcquisitionTypeEnum {
  export function toKeyValue() {
    const keys = Object.keys(AcquisitionTypeEnum);
    return keys.slice(keys.length / 2, keys.length - 1).map((key, index) => ({
      value: key.replace(/([a-z])([A-Z])/g, '$1 $2'),
      key: AcquisitionTypeEnum[key],
    }));
  }
}

export enum AksiaCoverageGPEnum {
  Diligence_on_this_fund = 1,
  Full_due_diligence_on_prior_fund_in_series,
  Fund_is_monitored_and_No_diligence,
  Full_due_diligence_on_fund_in_different_series,
  Met_with_manager_more_than_once__including_investment_team_onsite___,
  Other,
}

export namespace AksiaCoverageGPEnum {
  export function toKeyValue() {
    const keys = Object.keys(AksiaCoverageGPEnum);
    return keys.slice(keys.length / 2, keys.length - 1).map((key, index) => ({
      value: key
        .replace(/_and_/g, ' & ')
        .replace(/___/g, ')')
        .replace(/__/g, ' (')
        .replace(/_/g, ' '),
      key: AksiaCoverageGPEnum[key],
    }));
  }
}

export enum AksiaCoverageGPNewEnum {
  Tier_I__IDD___ODD_on_fund_associated_with_the_co____investment,
  Tier_I__IDD___ODD_within_past_4yrs_on_predecessor_fund_in_same_series_____program,
  Tier_II__IDD___ODD_within_past_4yrs_on_GP______s_fund_in_same_asset_class,
  Tier_II__ODD_on_prior_fund_in_any_series_____program_within_past_4yrs,
  Tier_III__At_least_2_existing_funds_in_the_same_asset_class_prior_to_current_associated_fund_and_Firm_AUM_of_at_least_$2_______5_bn,
  Tier_IV__All_others________and_at_least__________$250m_AUM_for_credit_participations_________,
}

export namespace AksiaCoverageGPNewEnum {
  export function toKeyValue() {
    const keys = Object.keys(AksiaCoverageGPNewEnum);
    return keys.slice(keys.length / 2, keys.length - 1).map((key, index) => ({
      value: key
        .replace(/__________/g, ' >')
        .replace(/_________/g, ')')
        .replace(/________/g, ' (')
        .replace(/_______/g, '.')
        .replace(/______/g, "'")
        .replace(/_____/g, '/')
        .replace(/____/g, '-')
        .replace(/___/g, '+')
        .replace(/__/g, ': ')
        .replace(/_/g, ' '),
      key: AksiaCoverageGPNewEnum[key],
    }));
  }
}

export enum AksiaStatusEnum {
  __0_Radar = 1,
  __1_PreliminaryReview,
  __2_FurtherReview,
  __3_DueDiligence,
  __4_Approved,
  __X4_NotApproved,
  __X_Halted,
}

export namespace AksiaStatusEnum {
  export function toKeyValue() {
    const keys = Object.keys(AksiaStatusEnum);
    return keys.slice(keys.length / 2, keys.length - 1).map((key, index) => ({
      value: key
        .replace(/__/g, '')
        .replace(/_/g, ' - ')
        .replace(/([a-z])([A-Z])/g, '$1 $2'),
      key: AksiaStatusEnum[key],
    }));
  }
}

export enum CoInvFeeStructureEnum {
  No_Fee__No_Carry = 1,
  Fee_and__or_Carry,
}

export namespace CoInvFeeStructureEnum {
  export function toKeyValue() {
    const keys = Object.keys(CoInvFeeStructureEnum);
    return keys.slice(keys.length / 2, keys.length - 1).map((key, index) => ({
      value: key.replace(/__/g, '/').replace(/_/g, ' '),
      key: CoInvFeeStructureEnum[key],
    }));
  }
}

export enum InstrumentEnum {
  CommonEquity = 1,
  PreferredEquity,
  Warrant,
  SeniorSecured,
  Secondlien,
  Unitranche,
  Mezzanine,
  InvestmentGradeCorporateBonds,
  HighYieldCorporateBonds,
  TradeClaims,
  GovernmentBonds,
  ConvertibleBonds,
  InflationLinked,
  Mortgage_backed_securities,
  CLOs,
  CDOs,
  Options,
  Future__Forward_Contracts,
  ContractForwardDerivatives,
  Swaps,
  CDS,
  FX,
  OtherDerivatives,
}

export namespace InstrumentEnum {
  export function toKeyValue() {
    const keys = Object.keys(InstrumentEnum);
    return keys.slice(keys.length / 2, keys.length - 1).map((key, index) => ({
      value: key
        .replace(/__/g, '/')
        .replace(/_/g, ' ')
        .replace(/([a-z])([A-Z])/g, '$1 $2'),
      key: InstrumentEnum[key],
    }));
  }
}

export enum SingleAssetPortfolioEnum {
  SingleAsset = 1,
  Portfolio,
}

export namespace SingleAssetPortfolioEnum {
  export function toKeyValue() {
    const keys = Object.keys(SingleAssetPortfolioEnum);
    return keys.slice(keys.length / 2, keys.length - 1).map((key, index) => ({
      value: key.replace(/([a-z])([A-Z])/g, '$1 $2'),
      key: SingleAssetPortfolioEnum[key],
    }));
  }
}

export enum PropertyTypeEnum {
  Office = 1,
  Multifamily,
  Office_and_Multifamily,
  Retail,
  Industrial,
  Hotels,
  Diversified,
  Life_Sciences__Lab,
  Other,
}

export namespace PropertyTypeEnum {
  export function toKeyValue() {
    const keys = Object.keys(PropertyTypeEnum);
    return keys.slice(keys.length / 2, keys.length - 1).map((key, index) => ({
      value: key.replace(/_and_/g, ' & ').replace(/_/g, ' '),
      key: PropertyTypeEnum[key],
    }));
  }
}

export enum IndustryGeneralistEnum {
  Generalist = 1,
  IndustrySpecialist,
}

export namespace IndustryGeneralistEnum {
  export function toKeyValue() {
    const keys = Object.keys(IndustryGeneralistEnum);
    return keys.slice(keys.length / 2, keys.length - 1).map((key, index) => ({
      value: key.replace(/([a-z])([A-Z])/g, '$1 $2'),
      key: IndustryGeneralistEnum[key],
    }));
  }
}

export enum FixedFloatingDebtEnum {
  Fixed = 1,
  Floating,
  Mixed,
}

export namespace FixedFloatingDebtEnum {
  export function toKeyValue() {
    const keys = Object.keys(FixedFloatingDebtEnum);
    return keys.slice(keys.length / 2, keys.length - 1).map((key, index) => ({
      value: key.replace(/([a-z])([A-Z])/g, '$1 $2'),
      key: FixedFloatingDebtEnum[key],
    }));
  }
}

export enum DeptSourcingEnum {
  CMBS = 1,
  Agency,
  DebtFund,
  Bank,
  LifeInsuranceCompany,
  Other,
}

export namespace DeptSourcingEnum {
  export function toKeyValue() {
    const keys = Object.keys(DeptSourcingEnum);
    return keys.slice(keys.length / 2, keys.length - 1).map((key, index) => ({
      value: key.replace(/([a-z])([A-Z])/g, '$1 $2'),
      key: DeptSourcingEnum[key],
    }));
  }
}

//#endregion

//#region Secondary

export enum DealTypeSecondaryEnum {
  Single_Fund = 1,
  Multi_Fund,
  GP__led_CV___Single_Asset,
  GP__led_CV___Multi_Asset,
  Tender_Offer,
  Direct_Secondary,
  Fund_Restructure,
  Spin__out,
  Strip_sale,
}

export namespace DealTypeSecondaryEnum {
  export function toKeyValue() {
    const keys = Object.keys(DealTypeSecondaryEnum);
    return keys.slice(keys.length / 2, keys.length - 1).map((key, index) => ({
      value: key.replace(/___/g, ' - ').replace(/__/g, '-').replace(/_/g, ' '),
      key: DealTypeSecondaryEnum[key],
    }));
  }
}

export enum SourceDealEnum {
  Broker = 1,
  GeneralPartner,
  SellingLP,
  Other,
  OtherIntermediary,
}

export namespace SourceDealEnum {
  export function toKeyValue() {
    const keys = Object.keys(SourceDealEnum);
    return keys.slice(keys.length / 2, keys.length - 1).map((key, index) => ({
      value: key.replace(/([a-z])([A-Z])/g, '$1 $2'),
      key: SourceDealEnum[key],
    }));
  }
}

export enum AksiaRoleEnum {
  Lead = 1,
  Participant,
}

export namespace AksiaRoleEnum {
  export function toKeyValue() {
    const keys = Object.keys(AksiaRoleEnum);
    return keys.slice(keys.length / 2, keys.length - 1).map((key, index) => ({
      value: key,
      key: AksiaRoleEnum[key],
    }));
  }
}

export enum DealDynamicEnum {
  Competitive = 1,
  LimitedProcess,
  Proprietary,
}

export namespace DealDynamicEnum {
  export function toKeyValue() {
    const keys = Object.keys(DealDynamicEnum);
    return keys.slice(keys.length / 2, keys.length - 1).map((key, index) => ({
      value: key.replace(/([a-z])([A-Z])/g, '$1 $2'),
      key: DealDynamicEnum[key],
    }));
  }
}

export enum RankEnum {
  _1st = 1,
  _2nd,
  _3rd,
  _4th,
  N__A,
}

export namespace RankEnum {
  export function toKeyValue() {
    const keys = Object.keys(RankEnum);
    return keys.slice(keys.length / 2, keys.length - 1).map((key, index) => ({
      value: key.replace('__', '/').replace('_', ''),
      key: RankEnum[key],
    }));
  }
}

export enum ReportingSourceEnum {
  Bloomberg = 1,
  Refused,
  ReportingInbox,
  Website,
  Other,
}

export namespace ReportingSourceEnum {
  export function toKeyValue() {
    const keys = Object.keys(ReportingSourceEnum);
    return keys.slice(keys.length / 2, keys.length - 1).map((key, index) => ({
      value: key.replace(/([a-z])([A-Z])/g, '$1 $2'),
      key: ReportingSourceEnum[key],
    }));
  }
}

//#endregion
